.albumprices_main {
    width: 100%;
    height: 100%;
}

.albumprices_inside {
    position: relative;
    height: 600px;
    width: 800px;
    background-color: white;
}

.albumprices_close-img {
    position: absolute;
    right: 15px;
    top: 15px;
}