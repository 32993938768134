.main-gotoapp {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: rgba($color: #000000, $alpha: 0.05);
  padding: 20px;
}

.text-gotoapp {
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  color: rgba($color: #000000, $alpha: 0.6);
}

.button-gotoapp {
  background-color: #89C1C1;
  width: 100%;
  height: 40px;
  border: solid 0px;
  border-radius: 5px;
  font-size: 15px;
  color: white;
}