.no-image {
  border: solid 1px;
  border-color: #F0F8FF;
  border-radius: 2px;
}

.no-image:hover {
  background-color: #F0F8FF;
}

.album-picker_card {
  height: 200px;
  width: 200px;
  border-radius: 10px;
}

.album-picker_card-no-image {
  height: 200px;
  width: 200px;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.2);
}

.album-picker_card-text {
  background-color: rgba(0,0,0,0.5);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 20%;
  color: white;
  font-weight: bold;
}

.album-picker-btn {
  width: 200px;
  height: 60px;
  color: white;
  border: hidden;
  background-color:  #00baf1;
  font-family: 'FuturaMd';
}