
.canvas-item_container {
    position: relative;
    width: 25%;
    height: calc(100vw * 23.75/100);
    // border: black 1px solid;
}



.canvas-item_img {
    object-fit: cover;
}

.canvas-item_photo {
    position: relative;
    // background-color: lightblue;
}

.canvas-item_edit-btn {
    position: absolute;
    top: 14%;
    right: 14%;
}

.canvas-item_lowresolution {
    position: absolute;
    bottom: 0;
}

.canvas-item_lowresolution-noframe {
    position: absolute;
    bottom: 5%;
}

@media(max-width: 1200px) {
    .canvas-item_lowresolution {
        position: absolute;
        bottom: -6%;
    }
}