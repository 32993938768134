.border-color {
    border-color: #606060;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
}

.hr-car {
    background-color: black;
    height: 1px;
    border: 0;
    color: black;
}

.hr-car-price {
    background-color: black;
    height: 1px;
    border: 1px;
    color: black;
}

.bg-page-image-cart {
    //margin-top: -125%;
    margin-left: -1%;
    height: 0;
    padding-top: 100%;
    color: white;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 200px) and (max-width: 320px) {
    .bg-page-image-cart {
        margin-top: -135%;
    }
    .border-color {
        padding: 0 !important;
        margin-top: 15px!important;
    }
}

@media (min-width: 321px) and (max-width: 375px) {
    .bg-page-image-cart {
        margin-top: -130%;
    }
    .border-color {
        padding: 0 !important;
        margin-top: 15px!important;
    }
}

@media (min-width: 376px) and (max-width: 410px) {
    .bg-page-image-cart {
        margin-top: -148%;
    }
}

@media (min-width: 411px) and (max-width: 413px) {
    .bg-page-image-cart {
        margin-top: -124%;
    }
    .border-color {
        padding: 0 !important;
        margin-top: 15px!important;
    }
}

@media (min-width: 414px) and (max-width: 480px) {
    .bg-page-image-cart {
        margin-top: -120%;
    }
    .border-color {
        padding: 0 !important;
        margin-top: 20px!important;
    }
}

@media (min-width: 481px) and (max-width: 510px) {
    .bg-page-image-cart {
        margin-top: -122%;
    }
}

@media (min-width: 511px) and (max-width: 550px) {
    .bg-page-image-cart {
        margin-top: -120%;
    }
}

@media (min-width: 551px) and (max-width: 575px) {
    .bg-page-image-cart {
        margin-top: -118%;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .bg-page-image-cart {
        margin-top: -120%;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .bg-page-image-cart {
        margin-top: -118%;
    }
}

@media (min-width: 1200px) and (max-width: 1750px) {
    .bg-page-image-cart {
        margin-top: -115%;
    }
}

@media (min-width: 1751px) {
    .bg-page-image-cart {
        margin-top: -118%;
    }
}


////////////////////////////////

.cart_main-container {
    background-color: white;
}

.cart_container {
    width: 50%;
    overflow-y: scroll;
    height: 85vh;
    // border: rgba(0,0,0,0.1) 1px solid;
}

.cart_container-inside {
    width: 70%;
    background-color: white;
    border: rgba(0,0,0,0.1) 2px solid;
    border-radius: 20px;
}

.cart_subtotal-card {
    border-top: rgba(0,0,0,0.1) 2px solid;
}

// .cart_container button {
//     margin: 30px 0px;
//     width: 90%;
//     height: 60px;
//     color: white;
//     border: hidden;
//     background-color:  #00baf1;
// }

.cart_bottom button {
    margin: 30px 0px;
    width: 40%;
    height: 60px;
    color: white;
    border: hidden;
    background-color:  #00baf1;
}

.cart_advertising-container {
    width: 70%;
}

.cart_instructions {
    width: 70%;
}

.cart_delivery-date {
    background-color: #F2F2F2;
    margin-right: -4%;
}

.cart_customer-info {
    width: 95%;
    margin-top: 70px;
    border: #C4C4C4 1px solid;
}

.cart_summary {
    width: 95%;
    // height: 200px;
    background-color: #F2F2F2;
}

.cart_info-item {
    // height: 70px;
}

.cart_info-inline {
    background-color: #C4C4C4;
    height: 0.01px;
    margin: 0px 2%;
}

.cart_info-inline-v2 {
    background-color: rgba(0,0,0,0.5);
    height: 0.01px;
    margin: 0px 2%;
}


.cart_select-address {
    display: none;
}

.cart_select-address-show {
    display: block;
}



@media(max-width: 1340px) {
    .cart_container-inside {
        width: 90%;
        background-color: white;
        border: rgba(0,0,0,0.1) 2px solid;
        border-radius: 20px;
    }

    .cart_advertising-container {
        width: 90%;
    }
    
    .cart_instructions {
        width: 90%;
    }
}
