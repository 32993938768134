.content-select select::-ms-expand {
    display: none;
}

.content-select {
    max-width: 300px;
    position: relative;
}

.content-select select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 1px 10px;
    height: 30px;
    outline: 0;
    border: 0;
    border-radius: 0;
    background: #fff;
    color: black;
    font-size: 1em;
    color: black;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    position: relative;
    transition: all 0.25s ease;
}

.content-select i {
    position: absolute;
    right: 20px;
    top: calc(50% - 13px);
    width: 16px;
    height: 16px;
    display: block;
    transform: rotate(-45deg);
    /* Giramos el cuadrado */
    transition: all 0.25s ease;
}

.content-select:hover i {
    margin-top: 3px;
}

.label-select {
    width: 112px;
}


/*
------------------------------------------
*/

.can-toggle {
    position: relative;
}

.can-toggle *,
.can-toggle *:before,
.can-toggle *:after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.can-toggle input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.can-toggle input[type="checkbox"][disabled]~label {
    pointer-events: none;
}

.can-toggle input[type="checkbox"][disabled]~label .can-toggle__switch {
    opacity: 0.4;
}

.can-toggle input[type="checkbox"]:checked~label .can-toggle__switch:before {
    content: attr(data-unchecked);
    left: 0;
}

.can-toggle input[type="checkbox"]:checked~label .can-toggle__switch:after {
    content: attr(data-checked);
}

.can-toggle label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.can-toggle label .can-toggle__label-text {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-left: 32px;
}

.can-toggle label .can-toggle__switch {
    position: relative;
}

.can-toggle label .can-toggle__switch:before {
    content: attr(data-checked);
    position: absolute;
    top: 0;
    text-transform: uppercase;
    text-align: center;
}

.can-toggle label .can-toggle__switch:after {
    content: attr(data-unchecked);
    position: absolute;
    z-index: 5;
    text-transform: uppercase;
    text-align: center;
    background: white;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.can-toggle input[type="checkbox"][disabled]~label {
    color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type="checkbox"]:focus~label .can-toggle__switch,
.can-toggle input[type="checkbox"]:hover~label .can-toggle__switch {
    background-color: #777;
}

.can-toggle input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover~label .can-toggle__switch:after {
    color: #5e5e5e;
}

.can-toggle input[type="checkbox"]:hover~label {
    color: #6a6a6a;
}

.can-toggle input[type="checkbox"]:checked~label:hover {
    color: #55bc49;
}

.can-toggle input[type="checkbox"]:checked~label .can-toggle__switch {
    background-color: #70c767;
}

.can-toggle input[type="checkbox"]:checked~label .can-toggle__switch:after {
    color: #4fb743;
}

.can-toggle input[type="checkbox"]:checked:focus~label .can-toggle__switch,
.can-toggle input[type="checkbox"]:checked:hover~label .can-toggle__switch {
    background-color: #5fc054;
}

.can-toggle input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
    color: #47a43d;
}

.can-toggle label .can-toggle__label-text {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.can-toggle label .can-toggle__switch {
    -webkit-transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
    transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
    background: #848484;
}

.can-toggle label .can-toggle__switch:before {
    color: rgba(255, 255, 255, 0.5);
}

.can-toggle label .can-toggle__switch:after {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    color: #777;
}

.can-toggle input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:hover~label .can-toggle__switch:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle input[type="checkbox"]:checked~label .can-toggle__switch:after {
    -webkit-transform: translate3d(65px, 0, 0);
    transform: translate3d(65px, 0, 0);
}

.can-toggle input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle label {
    font-size: 14px;
}

.can-toggle label .can-toggle__switch {
    height: 36px;
    -webkit-flex: 0 0 134px;
    -ms-flex: 0 0 134px;
    flex: 0 0 134px;
    border-radius: 4px;
}

.can-toggle label .can-toggle__switch:before {
    left: 67px;
    font-size: 12px;
    line-height: 36px;
    width: 67px;
    padding: 0 12px;
}

.can-toggle label .can-toggle__switch:after {
    top: 2px;
    left: 2px;
    border-radius: 2px;
    width: 65px;
    line-height: 32px;
    font-size: 12px;
}

.can-toggle label .can-toggle__switch:hover:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.can-toggle.can-toggle--size-small input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-small input[type="checkbox"]:hover~label .can-toggle__switch:after {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small input[type="checkbox"]:checked~label .can-toggle__switch:after {
    -webkit-transform: translate3d(44px, 0, 0);
    transform: translate3d(44px, 0, 0);
}

.can-toggle.can-toggle--size-small input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-small input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-small label {
    font-size: 13px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch {
    height: 28px;
    -webkit-flex: 0 0 90px;
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    border-radius: 2px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:before {
    left: 45px;
    font-size: 10px;
    line-height: 28px;
    width: 45px;
    padding: 0 12px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:after {
    top: 1px;
    left: 1px;
    border-radius: 1px;
    width: 44px;
    line-height: 26px;
    font-size: 10px;
}

.can-toggle.can-toggle--size-small label .can-toggle__switch:hover:after {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-large input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-large input[type="checkbox"]:hover~label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-large input[type="checkbox"]:checked~label .can-toggle__switch:after {
    -webkit-transform: translate3d(78px, 0, 0);
    transform: translate3d(78px, 0, 0);
}

.can-toggle.can-toggle--size-large input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle.can-toggle--size-large input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.can-toggle--size-large label {
    font-size: 14px;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch {
    height: 50px;
    -webkit-flex: 0 0 160px;
    -ms-flex: 0 0 160px;
    flex: 0 0 160px;
    border-radius: 4px;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch:before {
    left: 80px;
    font-size: 14px;
    line-height: 50px;
    width: 80px;
    padding: 0 12px;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch:after {
    top: 2px;
    left: 2px;
    border-radius: 2px;
    width: 78px;
    line-height: 46px;
    font-size: 14px;
}

.can-toggle.can-toggle--size-large label .can-toggle__switch:hover:after {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.can-toggle.demo-rebrand-1 input[type="checkbox"][disabled]~label {
    color: rgba(181, 62, 116, 0.5);
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:focus~label .can-toggle__switch,
.can-toggle.demo-rebrand-1 input[type="checkbox"]:hover~label .can-toggle__switch {
    background-color: #b53e74;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-1 input[type="checkbox"]:hover~label .can-toggle__switch:after {
    color: #8f315c;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:hover~label {
    color: #a23768;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked~label:hover {
    color: #39916a;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked~label .can-toggle__switch {
    background-color: #44ae7f;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked~label .can-toggle__switch:after {
    color: #368a65;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:focus~label .can-toggle__switch,
.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:hover~label .can-toggle__switch {
    background-color: #3d9c72;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:focus~label .can-toggle__switch:after,
.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:hover~label .can-toggle__switch:after {
    color: #2f7757;
}

.can-toggle.demo-rebrand-1 label .can-toggle__label-text {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.can-toggle.demo-rebrand-1 label .can-toggle__switch {
    -webkit-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
    background: #c14b81;
}

.can-toggle.demo-rebrand-1 label .can-toggle__switch:before {
    color: rgba(255, 255, 255, 0.6);
}

.can-toggle.demo-rebrand-1 label .can-toggle__switch:after {
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    color: #b53e74;
}

.select-mark {
    height: 25px;
    width: 25px;
}

.option-container {
    height: 3rem;
}


////////////////

.albums_header {
    background-color: rgba(0,0,0,0.05);
    height: 8vh;
    padding: 0px 70px;
}

.albums_config-bar {
    height: 8vh;
    width: 100%;
    background-color: #51B7F3;
}

.albums_name-container {
    position: relative;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    width: 400px;
}

.album_editname-btn {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: white;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
}

.album_hover-editname {
    position: absolute;
    top: 12px;
    right: -262px;
    width: 250px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    z-index: 1;
}