
.cart-item {
    border-top: rgba(0,0,0,0.1) 2px solid;
    height: calc(100vw * 11/100);
    min-height: 140px;
}

.cart-item_magnet-background {
    background: url('../../images/newDesignImages/magnets-background-img.png');
    background-size: cover; 
    height: 100px;
    width: 107px;
}

.cart-item_magnet-background img {
    object-fit: cover;
    border-radius: 5px;
}

.cart-item_canvas-fame-img {
    margin-top: 3%;
    margin-right: 1%;
    object-fit: cover;
}

.cart-item_canvas-background {
    background: url('../../images/newDesignImages/canvas-back-img.png');
    background-size: cover;     
    width: calc(100vw * 9/100);
    height: calc(100vw * 9/100);
}

.cart-item_canvas-background img {
    object-fit: cover;
}

.cart-item_ornament-background {
    background: url('../../images/newDesignImages/ornaments-background-img.png');
    background-size: cover; 
    height: 100px;
    width: 107px;
}

.cart-item_ornament-background img {
    object-fit: cover;
    border-radius: 5px;
}

.cart-item_accessories {
    width: calc(100vw * 9/100);
    height: calc(100vw * 9/100);
}

.cart-item_accessories img {
    object-fit: cover;
}


.cart-item_album-background {
    background: url('../../images/newDesignImages/album-img.png');
    background-size: cover; 
    height: 100px;
    width: 107px;
}

.cart-item_album-background img {
    object-fit: cover;
}

.cart-item_album {
    width: calc(100vw * 9.5/100);
    height: calc(100vw * 9/100);
}

.cart-item_album-left {
    width: 5%;
    height: 100%;
    box-shadow: 1px 0 5px -2px #888;
}

.cart-item_album-mask {
    position: absolute;
}

.cart-item_album-mask-border {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.cart-item_album-mask-header {
    top: 0;
}

.cart-item_album-right {
    position: relative;
    width: 95%;
    height: 100%;
}

.cart-item_album-right img {
    object-fit: cover;
}

.cart-item_magnet {
    width: calc(100vw * 9.5/100);
    height: calc(100vw * 9/100);
    background-image: url('../../images/newDesignImages/magnets-background-img.png');
    background-size: cover;
}

.cart-item_magnet img {
    object-fit: cover;
    border-radius: 10px;
}

.cart-item_ornament {
    width: calc(100vw * 9.5/100);
    height: calc(100vw * 9/100);
    background-image: url('../../images/newDesignImages/ornaments-background-img.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.cart-item_ornament img {
    object-fit: cover;
    border-radius: 75px;
}

.cart-item_inside {
    width: calc(100vw * 9.5/100);
    height: calc(100vw * 9/100);
}

.cart-item_giftcard-img {
    object-fit: contain;
}

.card-item_info-container {
    height: calc(100vw * 9/100);
    padding: 10px 0;
}