.bg-bar-title {
    background: #f2f2f2;
}

.pond-container {
    max-height: 80vh;
    overflow: scroll;
}

.dragndrop_header {
    background-color: rgba(0,0,0,0.05);
    height: 8vh;
    // padding: 0px 50px;
    padding-right: 50px;
    padding-left: 70px;
}

.dragndrop_main {
    height: 77vh;
    overflow-y: scroll;
    width: 100%;
}

.dragndrop_top-container {
    width: 100%;
    box-shadow: 0 4px 2px rgba(0,0,0,0.05);
}

.dragndrop_drop-container {
    border: #BDBDBD 2px dashed;
    width: 75%;
    max-width: 800px;
    height: 350px;
}

.dragndrop_file-container {
    height: 300px;
    width: 100%;
}

