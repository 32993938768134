.update-email_container {
    width: 100%;
    height: 100%;
}

.update-email_container-inside {
    width: 40%;
    height: 50%;
    min-width: 500px;
    min-height: 300px;
    background-color: white;
    border: #00baf1 solid 4px;
    border-radius: 10px;
}


.update-email_container-inside input {
    width: 60%;
    height: 40px;
    margin: 10px 0px;
    border: solid 1px rgba(0,0,0,0.2);
    border-radius: 3px;
    padding-left: 20px;
}

.update-email_buttons-container {
    width: 60%;
}

.update-email_ok-button {
    width: 45%;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #00baf1;
    border-radius: 3px;
}

.update-email_ok-button:hover {
    width: 45%;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #00baf1;
    border-radius: 3px;
    opacity: 0.8;
}

.update-email_cancel-button {
    width: 45%;
    height: 40px;
    color: #00baf1;
    border: #00baf1 2px solid;
    background-color:  white;
    border-radius: 3px;
}