.success-popup_main {
    width: 100%;
    height: 100%;
}

.success-popup_inside {
    position: relative;
    width: 600px;
    height: 600px;
    background-color: white;
}

.success-popup_close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.success-popup_img { 
    object-fit: cover;
    width: 269.38px;
    height: 190.49px;
}

.success-popup_buttons {
    width: 70%;
}

.success-popup_gotocart {
    width: 45%;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #51B7F3;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.success-popup_gotocart:hover {
    width: 45%;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #51B7F3;
    border-radius: 3px;
    opacity: 0.8;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.success-popup_buymore {
    width: 45%;
    height: 40px;
    color: #51B7F3;
    border: #51B7F3 2px solid;
    background-color:  white;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.success-popup_continue {
    width: 45%;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #9DD168;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}

@media (max-height: 650px) {
    .success-popup_inside {
        width: 500px;
        height: 500px;
        background-color: white;
    }
    .success-popup_buttons {
        width: 85%;
    }
}