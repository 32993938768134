.order-detail_main {
    background-color: rgba(0,0,0,0.03);
}

.order-detail_order-num {
    background-color: rgba(0,0,0,0.4);
}

.order-detail_container {
    // background-color: rosybrown;
    
}

.order-detail_container-inside {
    width: 90%;
    background-color: white;
    border: hidden;
    border-radius: 20px;
}

.order-detail_item {
    border-top: rgba(0,0,0,0.1) 2px solid;
}

.order-detail_item-gray {
    background-color: rgba(0,0,0,0.03);
}

.order-detail_img {
    width: 10%;
    // background-color: salmon;
}



.order-detail_item-content {
    width: 100%;
    // background-color: blue;
}

.order-detail_return-btn button{
    border: hidden;
    background-color: #626262;
    height: 50px;
    width: 50%;
}

