.canvas_main-content {
    height: 77vh;
    overflow-y: scroll;
}

.canvas_header {
    background-color: #F4F4F4;
    height: 8vh;
    padding: 0px 70px;
}

.canvas_header-right {
    width: 40%;
}

@media(max-width: 1180px) {
    .canvas_header-right {
        width: 60%;
    }
} 


@media(max-width: 850px) {
    .canvas_header-right {
        width: 80%;
    }
}



.canvas_content {
    width: 55%;
}

.canvas_content-header {
    background-color: rgba(0,0,0,0.05);
    height: 8vh;
}

.canvas_header-top {
    height: 40%;
}

.canvas_header-bottom {
    height: 60%;
}

.canvas_header-item {
    font-family: 'FuturaMd';
    font-size: 15px;
    color: rgba(0,0,0,0.6);
}

.canvas_header-item:hover {
    font-family: 'FuturaMd';
    font-size: 15px;
    // color: #51b7f3;
    color: #00baf1;
}


.canvas_photos-counter {
    width: 100%;
    height: 12vh;
}

.canvas_add-to-cart-btn {
    width: 35%;
    height: 50px;
    color: white;
    border: hidden;
}



// .canvas-item_container {
//     width: 33%;
// }

.canvas-item_container-inside {
    width: 80%;
}

.canvas-item_img-container {
    background-image: url('../../images/newDesignImages/canvas-back-img.png');
    background-size: cover;
    width: 100%;
    padding-bottom: 5%;
}

// .canvas-item_img {
//     width: 96%;
//     padding-bottom: 96%;
//     background-size: cover;
//     background-position: center;
// }

.canvas_warning-container {
    border-bottom: 2px solid rgba(0,0,0,0.1);
}



/////////////////////////

.canvas_frame-select {
    // height: 50%;
    height: calc(100vw * 20/100);
    width: 100%;
    background-color: #F9F9F9;
}

.canvas_frame-select-item {
    // border: black 1px solid;
    position: relative;
    height: 90%;
    width: 14%;
}

.canvas_check-img {
    position: absolute;
    top: -2%;
    right: -5%;
}

.canvas_check-img-noframe {
    position: absolute;
    top: 10%;
    right: 10%;
}

.canvas_photos-container {
    // background-color: blue;
    padding-bottom: 40px;
}

.canvas_photos {
    width: 100%;
}

.canvas_photos-inside {
    width: 95%;
}

.canvas_frame-description {
    font-family: 'GothamMedium';
    font-size: 15px;
    color: #6E7781;
    white-space: pre-line;
    text-align: center;
}

.canvas_frame-description-selected {
    font-family: 'GothamMedium';
    font-size: 15px;
    color: #51B7F3;
    white-space: pre-line;
    text-align: center;
}