.personal-data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
}

.form-container {
  width: 70%;
}

@media (max-width: 600px) {
  .personal-data-container {
    flex-direction: column;
    justify-content: center; 
    align-items: center;
  } 
  .form-container {
    width: 95%;
  }
}

.avatar {
  height: 200px;
  width: 200px;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  background-size: 100% auto;
  border-color: coral;
  object-fit: cover;
}

.round-input {
  background-color: #fff;
  margin: 0 auto 15px auto;
  padding: 5px;
  border: 1px solid #ccc;
  
  -moz-border-radius: 11px;
  -webkit-border-radius: 11px;
  border-radius: 11px;
}

.hide-input {
  display: none;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
}

.change-button {
  border-radius: 0.2rem;
  width: 170px;
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .button-container {
    display: flex;
    justify-content: center;
    margin-right: -10px;
    margin-left: 10px;
  }
  .change-button {
    width: 100%;
    margin: 10px;
    display: flex;
    justify-content: center;
  }
}


//////////////////////////////////

.myaccount_personal-info {
  background-color: rgba(0,0,0,0.03);
}

.myaccount_items {
  border-bottom: rgba(0,0,0,0.1) 2px solid;
}

.myaccount_change-password-btn {
  width: 60%;
  height: 60px;
  color: #00baf1;
  text-decoration: underline;
  border-bottom: rgba(0,0,0,0.1) 2px solid;
}

.myaccount_hr {
  width: 30%;
  border: rgba(0,0,0,0.1) 1px solid;
}

.checkout_voucher-btn {
  background-color: #626262;
  height: 50px;
  width: 30%;
  color: white;
}

.myaccoun_edit-container {
  width: 60px;
  height: 60px;
  border: #d3d8dd 3px solid;
  border-radius: 15px;
  background-color: white;

}
