.signup-main-wrapper {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url('../../images/signup.jpg');
    background-size: cover;
    background-position: center center;
}

.signpu_main {
    width: 100vw;
    height: 100vh;
    margin: 0;
}

.signup_left {
    width: 60%;
    height: 100%;
    background: url('../../images/newDesignImages/signup-img.png');
    background-size: cover;
    background-position: center;
}

.signup_right {
    width: 40%;
    height: 100%;
    // background-color: lightblue;
}

.signup_form {
    width: 100%;
    height: 75%;
}

.signup_logo-container {
    height: 25%;
    width: 100%;
    // background-color: lightblue;
}


.signup_input {
    width: 80%;
    height: 50px;
    background-color: rgba(0,0,0,0.05);
    border-radius: 3px;
    border: rgba(0,0,0,0.1) 1px solid;
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.signup_input::placeholder{
    font-family: 'GothamBold';
    color: #525353;
    font-size: 14px;
}

.signup_alt-register {
    width: 100%;
}

.signup_fb-register {
    width: 80%;
    background-color: white;
    border: black 1px solid;
    border-radius: 3px;
    height: 50px;
}

.signup_terms {
    width: 80%;
    margin-top: 10px;
}

.signup_terms-link {
    text-decoration: none;
    font-family: 'GothamBook';
    font-size: 13px;
    color: #525353;
}


.signup_register-btn {
    width: 80%;
    height: 50px;
    border: hidden;
    border-radius: 3px;
    background-color: #51B7F3;
    color: white;
    font-family: 'GothamBold';
    font-size: 15px;
}

.signup_link {
    font-family: 'GothamBook';
    font-size: 18px;
    color: #51B7F3;
    font-weight: 1000;
}
