.photo-detail-canvas_container {
    height: 100%;
    width: 100%;
    z-index: 3;
}

.photo-detail-canvas_inside-container {
    position: relative;
    height: 93%;
    width: 50%;
    min-width: 700px;
    min-height: 500px;
    background-color: white;
}

.photo-detail-canvas_top {
    height: 5%;
}

.photo-detail-canvas_close-img {
    position: absolute;
    right: 10px;
    top: 10px
}

.photo-detail-canvas_img-container {
    width: 500px;
    height: 500px;
    padding-bottom: 1.5%;
    padding-right: 1.5%;
}

.photo-detail-canvas_img {
    object-fit: cover;
}

.photo-detail-canvas_center {
    width: 100%;
    height: 60%;
}

.photo-detail_duplicate-btn {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 2px solid;
    color: #51B7F3;
}

.photo-detail_duplicate-btn-opaque {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 2px solid;
    color: #51B7F3;
    opacity: 50%;
}

.photo-detail-canvas_bottom {
    height: 35%;
    width: 100%;
}

.photo-detail-canvas_waning {
    height: 15%;
    width: 100%;
}

.photo-detail-canvas_edit {
    width: 100%;
    height: 20%;
}

.photo-detail-canvas_save-btn {
    width: 100%;
    height: 30%;
}

@media(max-height: 800px) {
    .photo-detail-canvas_inside-container {
        position: relative;
        height: 98%;
        width: 50%;
        min-width: 700px;
        min-height: 500px;
        background-color: white;
    }

    .photo-detail-canvas_img-container {
        width: 350px;
        height: 350px;
        padding-bottom: 1%;
        padding-right: 1%;
    }
}