.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

.collapse {
    display: none
}

.collapse-in {
    display: block
}

.collapse-card {
    display: none
}

.collapse-card-in {
    display: block
}

.btn-pixyalbum-border-dark {
    background: #89C1C1;
    color: #FFF;
}

.image-page-checkout {
    margin-top: -113px;
}

.bg-page-image-checkout {
    //margin-top: -125%;
    margin-left: -1%;
    height: 0;
    padding-top: 100%;
    color: white;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 200px) and (max-width: 320px) {
    .bg-page-image-checkout {
        margin-top: -155%;
    }
}

@media (min-width: 321px) and (max-width: 410px) {
    .bg-page-image-checkout {
        margin-top: -148%;
    }
}

@media (min-width: 411px) and (max-width: 413px) {
    .bg-page-image-checkout {
        margin-top: -128%;
    }
}

@media (min-width: 414px) and (max-width: 480px) {
    .bg-page-image-checkout {
        margin-top: -132%;
    }
}

@media (min-width: 481px) and (max-width: 649px) {
    .bg-page-image-checkout {
        margin-top: -126%;
    }
}

@media (min-width: 650px) and (max-width: 767px) {
    .bg-page-image-checkout {
        margin-top: -122%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .bg-page-image-checkout {
        margin-top: -113%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .bg-page-image-checkout {
        margin-top: -125%;
    }
}

@media (min-width: 1200px) and (max-width: 1750px) {
    .bg-page-image-checkout {
        margin-top: -118%;
    }
}

@media (min-width: 1751px) {
    .bg-page-image-checkout {
        margin-top: -118%;
    }
}


////////////////////////////////
.checkout_main-container {
    background-color: rgba(0,0,0,0.03);
}

.checkout_container {
    width: 100%;
}

.checkout_container-inside {
    width: 90%;
    background-color: white;
    border: rgba(0,0,0,0.1) 2px solid;
    border-radius: 20px;
}

.checkout_card {
    border-top: rgba(0,0,0,0.1) 2px solid;
}

.checkout_quantity {
    border: rgba(0,0,0,0.1) 2px solid;
    height: 30px;
    color: #00baf1;
}


.checkout_buy-button button {
    margin: 30px 0px;
    width: 40%;
    height: 60px;
    color: white;
    border: hidden;
    background-color:  #00baf1;
}

.checkout_title-container {
    border-bottom: rgba(0,0,0,0.1) 2px solid;
    width: 90%;   
}

.space-line {
    border: rgba(0,0,0,0.1) solid 1px;
}