
.giftcard_buy {
    height: 430px;
    background-image: url('../../images/newDesignImages/giftcard-background.jpg');
    background-size: cover;
}

.giftcard_buy-inside {
    width: 85%;
    height: 90%;
    background-color: white;
    border: solid 1px;
    border-color: rgba(0,0,0,0.2);
    border-radius: 20px;
}

@media (max-width: 1390px) {
    .giftcard_buy-inside {
        width: 90%;
        height: 90%;
        background-color: white;
        border: solid 1px;
        border-color: rgba(0,0,0,0.2);
        border-radius: 20px;
    }
}

.giftcard_buy-left {
    width: 100%;
}

.giftcard_buy-right {
    width: 100%;
}

.giftcard_design-card {
    border-radius: 20px;
}

.giftcard_design-card img {
    border-radius: 10px;
    object-fit: cover;
}


.giftcard_prices-container {
    width: 100%;
}

.giftcard_price {
    border: 2px solid;
    width: 28%;
    height: 55px;
    border-radius: 20px;
    font-family: 'FuturaBkBt';
    font-size: 15px;
}

.giftcard_category-container {
    width: 100%;
}

.giftcard_category-name {
    font-size: 15px;
}

.giftcard_destinatary-container {
    width: 100%;
    // background-color: burlywood;
}

.giftcard_input-container {
    border-bottom: rgba(0,0,0,0.2) 2px solid;
    width: 40%;
}

.giftcard_input-container input {
    padding-left: 10px;
    height: 50px;
    width: 85%;
    border: hidden;
    color: #626262;
    font-size: 15px;
}

.giftcard_message {
    border: rgba(0,0,0,0.2) 1px solid;
    border-radius: 20px;
}

.giftcard_message textarea {
    width: 100%;
    height: 100px;
    border-radius: 20px;
    border: none;
    resize: none;
    outline: none;
    color: #626262;
}

.giftcard_add-button {
    height: 50px;
    border: none;
    background-color: #00baf1;
    color: #ffffff;
}

.giftcard_characters {
    color: #00baf1;
}


.giftcard_exchange {
    height: 30rem;
    padding: 0px 100px;
}

.giftcard_exchange-right {
    background: url('../../images//newDesignImages/background-exchange-giftcard.png') no-repeat center;
}

.giftcard_top-text {
    font-size: 25px;
    font-weight: bold;
    font-family: 'FuturaMd';
}

.giftcard_bottom-text {
    font-size: 15px;
    color: #626262;
}


.giftcard_exchange-input {
    border: #00baf2 2px dashed;
    border-radius: 20px;
    width: 100%;
    height: 55px;
}

.giftcard_exchange-input input {
    border: hidden;
    padding-left: 10px;
    color: #626262;
}

.giftcard_exchange-button {
    border: hidden;
    width: 100%;
    height: 55px;
    background-color: #00baf1;
    color: #ffffff;
}

.giftcard-exchange_container {
    height: 100%;
}

.giftcard-exchanged_left {
    background: url('../../images/newDesignImages/background-exchange-giftcard.png') no-repeat center;
    width: 50%;
}

.giftcard-exchanged_right {
    width: 50%;
}

