.ornament_header {
    background-color: rgba(0,0,0,0.05);
    height: 8vh;
    border-bottom: rgba(0,0,0,0.3) 2px solid;
    padding: 0px 70px;
}

.ornament_header-right {
    width: 40%;
}

@media(max-width: 1180px) {
    .ornament_header-right {
        width: 60%;
    }
} 


@media(max-width: 850px) {
    .ornament_header-right {
        width: 80%;
    }
}

.ornament_main-content {
    height: 77vh;
    overflow-y: scroll;
}

.ornament_content {
    width: 55%;
}

.ornament_content-header {
    background-color: rgba(0,0,0,0.05);
    height: 8vh;
}

.ornament_header-top {
    height: 40%;
}

.ornament_header-bottom {
    height: 60%;
}

.ornament_header-item {
    font-family: 'FuturaMd';
    font-size: 15px;
    color: rgba(0,0,0,0.6);
}

.ornament_header-item:hover {
    font-family: 'FuturaMd';
    font-size: 15px;
    // color: #51b7f3;
    color: #00baf1;
}

.ornament-item_container {
    width: 20%;
}


.ornament-item_container-inside {
    width: 80%;
}

.ornament-item_container-inside-add {
    width: 80%;
    margin-top: 15%;
    border: rgba(0,0,0,0.1) 1px solid;
}

.ornament-item_img {
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
}

.card_ornament {
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-image: url('../../images/newDesignImages/ornament_placeholder_circle.png');
}

.ornament-item_top_img {
    height: 12.5%;
    margin-left: 42.5%;
    margin-right: 42.5%;
    background-size: cover;
    background-position: center;
    background-image: url('../../images/newDesignImages/ornament_placeholder_top.png');
}

.ornament-item_add-img {
    width: 100%;
}

.ornament_bar {
    height: 4px;
    width: 30%;
    background-color: rgba(0,0,0,0.2);
    border-radius: 5px;
}

.ornament_bar-inside {
    height: 100%;
    background-color: #00baf1;
    border-radius: 5px;
}

.ornament-item_add-img-top {
    height: 40px;
}

.ornament_add-to-cart-btn {
    width: 35%;
    height: 50px;
    color: white;
    border: hidden;
}


.ornament_counter {
    width: 30%;
}

.fa-exclamation-triangle {
    // color: black;
    color: #ffbf00;
}

.ornament_warning-container {
    border-bottom: 2px solid rgba(0,0,0,0.1);
}