.createalbumconfig_main {
    width: 100%;
    height: 115vh;
}

.createalbumconfig_inside {
    width: 800px;
}

.createalbumconfig_row {
    width: 80%;
}

.createalbumconfig_standard-btn {
    background-color: white;
    height: 50px;
    width: 49%;
    border: 1px solid;
    border-radius: 10px;
    font-family: 'GothamBold';
    font-size: 15px;
}

.createalbumconfig_pages-btn {
    background-color: white;
    height: 50px;
    width: 32%;
    border: 1px solid;
    border-radius: 10px;
    font-family: 'GothamBold';
    font-size: 15px;
}

.createalbumconfig_next-btn {
    color: white;
    background-color: #51b7f3;
    height: 50px;
    width: 250px;
    border: hidden;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}