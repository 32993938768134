
.create-card_inside {
    position: relative;
    width: 700px;
    height: 550px;
    // falta min-width y min-height
    background-color: white;
}

.create-card_card {
    position: relative;
    width: 300px;
    height: 180px;
    // background-color: #51B7F3;
    border-radius: 10px;
    // background: url('../../images//newDesignImages/card-default.png') no-repeat center; 
}

.create-card_chip-img {
    position: absolute;
    top: 20px;
    left: 10px;
}

.create-card_brand-img {
    position: absolute;
    top: 20px;
    right: 10px;
}

.crate-card_cvc-container {
    position: relative;
    height: 50px;
    border-left: rgba(0,0,0,0.3) 1px solid;
}

.create-card_cvv-instructions {
    position: absolute;
    right: -50px;
    bottom: 50px;
    border-radius: 5px;
    box-shadow: 3px 3px 2px rgba(0,0,0,0.05);
}

.crate-card_cvc-container input {
    padding-left: 10px;
    height: 50px;
    width: 80%;
    border: hidden;
    color: #626262;
    font-size: 15px;
}


.create-card_card-num {
    font-family: 'GemunuSemiBold';
    font-size: 20px;
    color: white;
    letter-spacing: 5px;
}

.create-card_card-cardholder {
    position: absolute;
    bottom: 25%;
    left: 5%;
    font-family: 'GemunuRegular';
    font-size: 16px;
    color: white;
}

.create-card_card-expiration {
    position: absolute;
    bottom: 25%;
    right: 10%;
    font-family: 'GemunuRegular';
    font-size: 16px;
    color: white;
}