.banner-info_container {
    height: 100%;
    width: 100%;
}

.banner-info_container-inside {
    background-color: white;
    width: 50%;
    height: auto;
    min-height: 400px;
    min-width: 500px;
    border-radius: 15px;
}

.banner-info_container-inside img {
    border-radius: 15px  15px 0px 0px;
}

.banner-info_container-bottom {
    height: auto;
}

.banner-info_button {
    width: 90%;
    height: 40px;
    border: hidden;
    background-color: #00baf1;
}