.pricestable_odd {
    height: 40px; 
    width: 49.5%;
    background-color: #EDF1FF;
}

.pricestable_even {
    height: 40px; 
    width: 49.5%;
    background-color: #ffffff;
}