.alert-card {
    min-width: 26rem;
}

.bg-bar {
    background: #f2f2f1;
}

.alert-card-bar {
    min-width: 58rem;
    min-height: 260px;
}

.size-logo {
    width: 12%
}

//////////////////
.alert_container {
    height: 100%;
    width: 100%;
}

.alert_container-inside {
    height: 30%;
    width: 30%;
    min-width: 500px;
    background-color: white;
    border: #00baf1 solid 4px;
    border-radius: 10px;
}