.fa-check {
    color: green;
}

.f-w-350 {
    font-weight: 350;
    color: #222000!important;
    padding: 5px;
}

.book-border {
    width: 5px;
    height: 63.5%;
    margin-top: -15px;
    margin-left: -33px;
}

.warning-message {
    left: 90%;
    color: #F5D109;
    cursor: pointer;
}

.color-warning {
    color: #F5D109;
}

.message-incomplete {
    left: 96%;
    top: 9%;
    height: 100px;
    width: 230px;
    opacity: 0;
    color: black;
}

.warning-message:hover .message-incomplete {
    opacity: 1;
}

.bg-image {
    height: 0;
    padding-top: 100%;
    color: white;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.square-album {
    width: 100%;
    height: 100%;
    padding-top: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.bg-image-photo {
    margin-top: -122%;
}

.contain-cover {
    object-fit: cover;
    width: 100%;
    background-size: 100%;
}

.bg-image {
    background-size: 124%;
}

.bg-page-image-album {
    //margin-top: -125%;
    margin-left: -1%;
    height: 0;
    padding-top: 100%;
    color: white;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 200px) and (max-width: 320px) {
    .bg-page-image-album {
        margin-top: -144%;
    }
}

// @media (min-width: 330px) and (max-width: 413px) {
//     .bg-page-image-album {
//         margin-top: -118%;
//     }
// }
@media (min-width: 321px) and (max-width: 340px) {
    .bg-page-image-album {
        margin-top: -126%;
    }
}

@media (min-width: 341px) and (max-width: 374px) {
    .bg-page-image-album {
        margin-top: -123%;
    }
}

@media (min-width: 375px) and (max-width: 399px) {
    .bg-page-image-album {
        margin-top: -122%;
    }
}

@media (min-width: 400px) and (max-width: 412px) {
    .bg-page-image-album {
        margin-top: -120%;
    }
}

@media (min-width: 413px) and (max-width: 430px) {
    .bg-page-image-album {
        margin-top: -120%;
    }
}

@media (min-width: 431px) and (max-width: 460px) {
    .bg-page-image-album {
        margin-top: -117%;
    }
}

@media (min-width: 461px) and (max-width: 479px) {
    .bg-page-image-album {
        margin-top: -116%;
    }
}

@media (min-width: 480px) and (max-width: 490px) {
    .bg-page-image-album {
        margin-top: -116%;
    }
}

@media (min-width: 491px) and (max-width: 500px) {
    .bg-page-image-album {
        margin-top: -114%;
    }
}

@media (min-width: 501px) and (max-width: 535px) {
    .bg-page-image-album {
        margin-top: -115%;
    }
}

@media (min-width: 536px) and (max-width: 575px) {
    .bg-page-image-album {
        margin-top: -112%;
    }
}

@media (min-width: 576px) and (max-width: 649px) {
    .bg-page-image-album {
        margin-top: -115%;
    }
}

@media (min-width: 650px) and (max-width: 679px) {
    .bg-page-image-album {
        margin-top: -115%;
    }
}

@media (min-width: 680px) and (max-width: 735px) {
    .bg-page-image-album {
        margin-top: -114%;
    }
}

@media (min-width: 736px) and (max-width: 767px) {
    .bg-page-image-album {
        margin-top: -112%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .bg-page-image-album {
        margin-top: -120%;
    }
}

@media (min-width: 1024px) and (max-width: 1150px) {
    .bg-page-image-album {
        margin-top: -120%;
    }
}

@media (min-width: 1151px) and (max-width: 1199px) {
    .bg-page-image-album {
        margin-top: -117%;
    }
}

@media (min-width: 1200px) and (max-width: 1260px) {
    .bg-page-image-album {
        margin-top: -128%;
    }
}

@media (min-width: 1261px) and (max-width: 1270px) {
    .bg-page-image-album {
        margin-top: -126%;
    }
}

@media (min-width: 1271px) and (max-width: 1310px) {
    .bg-page-image-album {
        margin-top: -126%;
    }
}

@media (min-width: 1311px) and (max-width: 1400px) {
    .bg-page-image-album {
        margin-top: -127%;
    }
}

@media (min-width: 1401px) and (max-width: 1450px) {
    .bg-page-image-album {
        margin-top: -127%;
    }
}

@media (min-width: 1451px) and (max-width: 1550px) {
    .bg-page-image-album {
        margin-top: -122%;
    }
}

@media (min-width: 1551px) and (max-width: 1650px) {
    .bg-page-image-album {
        margin-top: -122%;
    }
}

@media (min-width: 1651px) and (max-width: 1750px) {
    .bg-page-image-album {
        margin-top: -118%;
    }
}

@media (min-width: 1751px) and (max-width: 1950px) {
    .bg-page-image-album {
        margin-top: -115%;
    }
}

@media (min-width: 1951px) {
    .bg-page-image-album {
        margin-top: -112%;
    }
}

.albums-card-cover {
    width: 260px;
    height: 250px;
    border: solid 1px;
    border-color: rgba(0,0,0,0.1);
    box-shadow: 3px 2px 1px 2px rgba(0,0,0,0.1);
}

.left-side {
    width: 10%;
    border-right-style: solid;
    border-color: rgba(0,0,0,0.05);
} 

.righ-side {
    width: 90%
}

.image-styles {
    object-fit: cover;
    height: 90%;
    width: 100%;
}

////////////////////////

// .album-card_container {
//     height: 450px;
//     width: 300px;
// }

.album-card_top-predesign {
    background-image:  url('../../../images/newDesignImages/album-img.png');
    background-size: cover;
    width: 300px;
    height: 290px;
    opacity: 0.85;
}

.album-card_top-predesign img {
    height: 290px;
    width: 300px;
}

// .album-card_top-normal {
//     background-image:  url('../../../images/newDesignImages/album-img.png');
//     background-size: cover;
//     width: 300px;
//     height: 290px;
// }

// .album-card_top-normal img {
//     object-fit: cover;
//     width: 270px;
//     height: 270px;
//     padding-right: 10px;
// }

.album-card_button {
    width: 70%;
    height: 40px;
    border-radius: 2px;
    color: white;
    border: hidden;
    background-color:  #00baf1;
}


/////////////////

.album-card_container {
    position: relative;
    width: 30%;
}

.album-card_edit-btn {
    position: absolute;
    top: -25px;
    right: 20px;
    width: calc(100vw * 5/100);
    height: calc(100vw * 5/100);
    border-radius: calc(100vw * 5/100);
    background-color: white;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
    z-index: 1;
}

.album-card_edit {
    position: absolute;
    top: 80%;
    right: -230px;
    width: 250px;
    height: 50px;
    border-radius: 10px;
    background-color: white;
    z-index: 2;
}

@media(max-width: 1250px) {
    .album-card_edit-btn {
        position: absolute;
        top: -15px;
        right: 10px;
        width: calc(100vw * 5/100);
        height: calc(100vw * 5/100);
        border-radius: calc(100vw * 5/100);
        background-color: white;
        box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
        z-index: 1;
    }
    
}

.album-card_top-normal {
    width: 80%;
    position: relative;
}

.album-card_album {
    width: 100%;
    height: calc(100vw * 21/100);
}


.album-card_left {
    width: 5%;
    height: 100%;
    box-shadow: 1px 0 5px -2px #888;
}

.album-card_right {
    width: 95%;
    height: 100%;
}

.album-card_warning {
    height: 40px;
}