.image-item {
    width: 100%;
    height: 100%;
}

ul,
li {
    list-style-type: none;
}

.grid-list-container {
    padding-left: 20%;
    padding-right: 20%;
}

.previeBook {
    width: 140px;
    height: 140px;
}

.checkbox-selection-delete-left {
    position: relative;
    top: 60px;
    left: 126px;
    margin: -7px;
    background-color: #FFFFFF;
    //width: 140px;
    cursor: crosshair;
}

.checkbox-selection-delete {
    position: relative;
    top: 60px;
    left: 150px;
    margin: -7px;
    background-color: #FFFFFF;
    //width: 140px;
    cursor: crosshair;
}

.checkbox-selectionDelete:hover {
    background-color: red;
}

.change-cover:hover {
    cursor: pointer;
    color: blue;
}

.square-page {
    width: 90%;
    height: 0px;
    padding-bottom: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
}

.square-page-content {
    // background: red;
    width: 90%;
    height: 100%;
    position: absolute;
}

.fluid-contain {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.checkbox-image {
    width: 30%;
    cursor: default;
}

.warning-text {
    opacity: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    color: black;
    font-family: 'Source Sans Pro', sans-serif;
    position: absolute;
}

.hover-warnig-text:hover .warning-text {
    opacity: 1;
}

.btn-add:hover {
    color: #6c757d;
}

.img-logo {
    width: 30%;
    opacity: .7;
    margin-bottom: 5px;
}

.vertical-spine-text {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    // right: -3px;
    font-size: 4px;
    position: absolute;
    top: 28px;
    right: -2px;
    width: 58px;
    margin-right: -21px;
}

.vertical-logo {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    opacity: .7;
    z-index: 1;
}

.spine-portada {
    position: relative;
    color: #d1cfcf;
}

.icon-color-gray {
    color: gray;
}

$font-size: 0.14vw;
$line-height: 1;
$lines: 3;
.text-album-date {
    display: block;
    display: -webkit-box;
    height: $font-size * $line-height * $lines;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    position: absolute;
    margin-top: -7.4vw;
    margin-left: 5.0vw;
}

.text-album-date-left {
    display: block;
    display: -webkit-box;
    height: $font-size * $line-height * $lines;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    position: absolute;
    margin-top: -7.4vw;
    margin-left: 1.5vw;
}

.text-album-footer {
    padding: 15%;
    display: block;
    margin-top: -22%;
    display: -webkit-box;
    height: $font-size * $line-height * $lines;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.bg-book {
    background: #FFF;
    z-index: 2;
}

@media (max-width: 565px) {
    .title-regular-16 {
        font-size: 12px;
    }
}

///////////////////////////


.sortablelistbook_header {
    height: 8vh;
    padding: 0px 70px;
}

.sortablelistbook_main-content {
    position: relative;
    height: 77vh;
    overflow-y: scroll;
}


.sortablelist_select-container {
    width: 80%;
    height: 25px;
}

.sortablelist_warning-container {
    width: 80%;
    height: 25px;
}

.sortablelist_warning-text {
    border-radius: 4px;
    background-color: white;
}

.sortablelistbook_item {
    font-family: 'GothamMedium';
    font-size: 15px;
    color: #929292;
    width: 160px;
    background-color: 'blue';
}


.sortablelistbook_item:hover {
    font-family: 'GothamMedium';
    font-size: 15px;
    color: #51b7f3;
}

.sortable-item_warning {
    font-family: 'GothamMedium';
    font-size: 10px;
    color: #525353;
}

.sortable-list_add_img {
    height: 80px;
}

.sortablelistbook_title-container {
    width: 100%;
}

@media (max-width: 1350px) and (min-width: 1200px) {
    .sortable-item_warning {
        font-family: 'GothamMedium';
        font-size: 8px;
        color: #525353;
    }
}

@media (min-width: 1200px) {
    .sortable-list_add_img {
        height: 60px;
    }
}