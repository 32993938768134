.failure-popup_img {
    object-fit: cover;
    width: 274px;
    height: 190.49px;
}

.failure-popup_btn {
    width: 50%;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #51B7F3;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.failure-popup_btn:hover {
    width: 50%;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #51B7F3;
    border-radius: 3px;
    opacity: 0.8;
    font-family: 'GothamMedium';
    font-size: 15px;
}