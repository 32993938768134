.alert-card {
  min-width: 26rem;
}

.bg-bar {
  background: #f2f2f1;
}

.size-logo {
  width: 12%
}