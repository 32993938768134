.image-counter_counter {
    height: 16vh;
}

.image-counter_bar {
    height: 4px;
    width: 30%;
    background-color: rgba(0,0,0,0.2);
    border-radius: 5px;
}
  
.image-counter_bar-inside {
    height: 100%;
    background-color: #51B7F3;
    border-radius: 5px;
}