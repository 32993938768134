// .product-card_container {
//     border-radius: 10px;
//     box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
// }


// .product-card_container img {
//     object-fit: cover;
//     // border-radius: 10px 10px 0px 0px;
//     height: 220px;
//     width: 250px;
// }

// .product-card_container img {
//     object-fit: cover;
//     border-radius: 10px 10px 0px 0px;
//     height: 220px;
//     width: 250px;
// }

// @media (max-width: 1180px) {
//     .product-card_container img {
//         object-fit: cover;
//         border-radius: 10px 10px 0px 0px;
//         height: 150px;
//         width: 180px;
//     }
// }

// .product-card_clean img {
//     object-fit: cover;
// }

////////////
.product-card_container {
    width: 25%;
}

.product-card_container-inside {
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
    width: 95%;
    height: calc(100vw * 112/500);
}

.product-card_container-inside:hover {
    box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.5);
    width: 95%;
    height: calc(100vw * 112/500);
}

.product-card_container-inside img {
    object-fit: cover;
    height: 82%;
    width: 100%;
}

.product-card_text {
    height: 18%;
}

.product-card_clean img {
    object-fit: cover;
}