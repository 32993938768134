.albuminfo_main {
    width: 270px;
}

.albuminfo_title {
    background-color: #ADB8E1;
    width: 100%;
    height: 30px;
}

.albuminfo_left-odd {
    height: 40px; 
    width: 34.5%;
    background-color: #EDF1FF;
}

.albuminfo_right-odd {
    height: 40px; 
    background-color: #EDF1FF; 
    width: 64.5%;
}

.albuminfo_left-even {
    height: 40px; 
    width: 34.5%;
    background-color: white;
}

.albuminfo_right-even {
    height: 40px; 
    width: 64.5%;
    background-color: white;
}

.albuminfo_prices-btn {
    color: white;
    background-color: #ADB8E1;
    height: 40px;
    border: hidden;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.albuminfo_start-btn {
    color: white;
    background-color: #51b7f3;
    height: 50px;
    border: hidden;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}