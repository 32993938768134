.square-page-cover {
    width: 100%;
    height: 0px;
    padding-bottom: 100%;
    //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.square-page-content-cover {
    width: 100%;
    height: 100%;
    position: absolute;
}

.fluid-contain-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.shadow-left-page {
    -webkit-box-shadow: -9px 9px 11px -3px rgba(184, 177, 184, 0.68);
    -moz-box-shadow: -9px 9px 11px -3px rgba(184, 177, 184, 0.68);
    box-shadow: -9px 9px 11px -3px rgba(184, 177, 184, 0.68);
}

.spine {
    background: white;
}


/////////////////

.coverheader_page-right {
    position: relative;
}

.coverheader_cover-img {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;
}