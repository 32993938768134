.accessories-card_main {
    width: 25%;
}

.accessories-card_content {
    width: 90%;
    height: 90%;
}


.accessories-card_img-container {
    width: 100%;
    height: 22.5vw;
}

.accessories-card_data {
    width: 100%;
}

.accessories-card_text {
    font-family: 'GothamMedium';
    font-size: 30px;
    color: rgba(0, 0, 0, 1);
}




