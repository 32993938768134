.edit-photo_container {
    height: 100%;
    width: 100%;

}

// .edit-photo_inside-container {
//     height: 100%;
//     width: 60%;
//     padding-top: 30px;
// }

.edit-photo_inner-inside {
    background-color: white;
    height: 90%;
    width: 100%;
    border: #00baf1 solid 4px;
    border-radius: 10px;
}

.edit-photo_crop-container {
    position: absolute;
    top: 10%;
    left: 25%;
    right: 25%;
    bottom: 35%;
}

.edit-photo_top-container {
    height: 40px;
}

.edit-photo_slider-container {
    width: 100%;
}

.edit-photo_save button {
    margin: 10px 0px;
    width: 50%;
    height: 40px;
    color: white;
    border: hidden;
    background-color:  #00baf1;
}


/////////////////

.edit-photo_inside-container {
    position: relative;
    height: 700px;
    width: 800px;
    background-color: white;
}


.edit-photo_save-btn {
    width: 250px;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #51B7F3;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.edit-photo_save-btn:hover {
    width: 250px;
    height: 40px;
    color: white;
    border: hidden;
    background-color: #51B7F3;
    border-radius: 3px;
    opacity: 0.8;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.edit-photo_slider {
    width: 40%;
}

@media (max-height: 700px) {
    .edit-photo_inside-container {
        position: relative;
        height: 500px;
        width: 600px;
        background-color: white;
    }

    .edit-photo_crop-container {
        position: absolute;
        top: 5%;
        left: 25%;
        right: 25%;
        bottom: 40%;
    }
}
