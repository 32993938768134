.magnet_header {
    background-color: rgba(0,0,0,0.05);
    height: 8vh;
    border-bottom: rgba(0,0,0,0.3) 2px solid;
    padding: 0px 70px;
}

.magnet_header-right {
    width: 40%;
}

@media(max-width: 1180px) {
    .magnet_header-right {
        width: 60%;
    }
} 


@media(max-width: 850px) {
    .magnet_header-right {
        width: 80%;
    }
}

.magnet_main-content {
    height: 77vh;
    overflow-y: scroll;
}

.magnet_content {
    width: 55%;
}

.magnet_content-header {
    background-color: rgba(0,0,0,0.05);
    height: 8vh;
}

.magnet_header-top {
    height: 40%;
}

.magnet_header-bottom {
    height: 60%;
}

.magnet_header-item {
    font-family: 'FuturaMd';
    font-size: 15px;
    color: rgba(0,0,0,0.6);
}

.magnet_header-item:hover {
    font-family: 'FuturaMd';
    font-size: 15px;
    // color: #51b7f3;
    color: #00baf1;
}

.magnet-item_container {
    width: 20%;
}


.magnet-item_container-inside {
    width: 80%;
}

.magnet-item_container-inside-add {
    width: 80%;
    margin-top: 15%;
    border: rgba(0,0,0,0.1) 1px solid;
}

.magnet-item_img {
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 8%;
}



.magnet-item_add-img {
    width: 100%;
}

.magnet_bar {
    height: 4px;
    width: 30%;
    background-color: rgba(0,0,0,0.2);
    border-radius: 5px;
}

.magnet_bar-inside {
    height: 100%;
    background-color: #00baf1;
    border-radius: 5px;
}

.magnet-item_add-img-top {
    height: 40px;
}

.magnet_add-to-cart-btn {
    width: 35%;
    height: 50px;
    color: white;
    border: hidden;
}


.magnet_counter {
    width: 30%;
}

.fa-exclamation-triangle {
    // color: black;
    color: #ffbf00;
}

.magnet_warning-container {
    border-bottom: 2px solid rgba(0,0,0,0.1);
}

