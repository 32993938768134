.footer_main-container {
    height: 40vh;
    width: 100%;
    background-color:#F2F2F2;
}

.footer_top-container {
    height: 10vh;
    padding: 0px 50px;
} 


.footer_bottom-container {
    height: 30vh;
    margin: 0 10%;
}

.footer_section-title {
    font-family: 'GothamBold';
    font-size: 15px;
    color: #606060;
}

.footer_item, .footer_item:hover {   
    text-decoration: none;
    font-family: 'GothamBook';
    font-size: 15px;
    color: #606060;
}