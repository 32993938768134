.sortable-list_main {
    padding: 0 1%;
}

.sortable-list_item {
    width: 16.33%;
    height: calc(100vw / 6);
    
}

