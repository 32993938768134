    .header {
        background: #f2f2f2;
    }
    
    .pointer {
        cursor: pointer;
    }
    
    .logo-nav-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .logo img {
        width: 63px;
        height: 50px;
    }
    
    .navbar {
        min-height: 4.5rem;
        display: flex;
        flex: 1;
        justify-content: space-between;
    }
    
    .navbarTitle {
        display: flex;
        color: white;
        align-items: center;
    }
    
    .notify-badge-car {
        position: absolute;
        background: #89C1C1;
        height: 1.2rem;
        top: -0.6rem;
        left: 1.4rem;
        width: 1.2rem;
        text-align: center;
        line-height: 1.2rem;
        font-size: 0.6rem;
        border-radius: 50%;
        color: white;
        border: 1px solid #89C1C1;
    }
    
    .color-icon {
        color: #6c757d;
    }
    
    .nav ul {
        background: #89C1C1;
        display: flex;
    }
    
    .logo-pixy {
        width: 70%
    }
    
    @media only screen and (min-width: 150px) and (max-width: 480px) {
        .ico-user-200 {
            width: 10%;
            height: 20%;
        }
        .logo-pixy {
            width: 180%;
        }
        .underline {
            display: none;
        }
        .navbar {
            font-size: 14px;
            padding: 0;
        }
    }
    
    @media (max-width: 767px) {
        .ico-user-200 {
            width: 10%;
            height: 20%;
        }
        .nav li {
            position: relative;
            display: flex;
        }
        .nav li ul {
            display: none;
            position: absolute;
            top: 100%;
            left: -120px;
            background-color: #fff;
            width: 170px;
        }
        .nav li ul li {
            padding: 5px;
        }
        .nav li:hover ul {
            display: flex;
            flex-direction: column;
        }
        .nav li ul li:hover {
            color: #89C1C1;
            cursor: pointer;
        }
    }
    
    @media (min-width:768px) and (max-width:1000px) {
        .nav li {
            position: relative;
            display: flex;
        }
        .nav li ul {
            display: none;
            position: absolute;
            top: 100%;
            left: -120px;
            background-color: #fff;
            width: 170px;
        }
        .nav li ul li {
            padding: 5px;
        }
        .nav li:hover ul {
            display: flex;
            flex-direction: column;
        }
        .nav li ul li:hover {
            color: #89C1C1;
            cursor: pointer;
        }
        .logo-pixy {
            width: 100%
        }
    }
    
    @media (min-width:1001px) {
        .nav li {
            position: relative;
            display: flex;
        }
        .nav li ul {
            display: none;
            position: absolute;
            top: 100%;
            left: -120px;
            background-color: #fff;
            width: 170px;
        }
        .nav li ul li {
            padding: 5px;
        }
        .nav li:hover ul {
            display: flex;
            flex-direction: column;
        }
        .nav li ul li:hover {
            color: #89C1C1;
            cursor: pointer;
        }
        .logo-pixy {
            width: 110%
        }
    }
    
    .user-sesion {
        cursor: pointer;
    }
    
    .menu-user {
        z-index: 1;
    }
    
    .ico-user-200 {
        width: 55%;
        height: 95%;
    }
    
    .ico-user-100 {
        width: 15%;
        height: 85%;
    }
    
    .ico-close-100 {
        width: 15%;
        height: 85%;
    }

    .ico-pixyp-100 {
        width: 15%;
        height: 85%;
    }
    
    span.underline p {
        color: black;
        text-decoration: none;
    }
    
    .pop-products {
        z-index: 1;
        width: 150%;
        height: 70%;
    }
    
    .bg-blue {
        background: #e7f2f7;
    }
    
    // SLIDER 
    .slider {
        width: 95%;
        margin: auto;
        overflow: hidden;
    }
    
    .slider ul {
        display: flex;
        padding: 0;
        width: 300%;
        animation: cambio 20s infinite alternate linear;
    }
    
    .slider li {
        width: 100%;
        list-style: none;
    }
    
    .slider img {
        width: 100%;
    }
    
    @keyframes cambio {
        0% {
            margin-left: 0;
        }
        20% {
            margin-left: 0;
        }
        25% {
            margin-left: -100%;
        }
        45% {
            margin-left: -100%;
        }
        50% {
            margin-left: -200%;
        }
        70% {
            margin-left: -200%;
        }
        75% {
            margin-left: -300%;
        }
        100% {
            margin-left: -300%;
        }
    }
    
    .pop-products {
        visibility: hidden
    }
    
    .underline:hover .pop-products {
        visibility: visible;
        cursor: default;
        z-index: 3;
    }
    
    span.underline {
        border-bottom: 3px solid #C83C7D;
    }
    
    .bg-popup {
        background: #FFF;
    }

    .my-orders-link {
        color: rgba(0,0,0,0.8);
    }

//////////////////


.navbar_container {
    height: 15vh;
    box-shadow: 0 3px 2px rgba(0,0,0,0.05);
    z-index: 1;
}

.navbar_top {
    height: 9vh;
    margin: 0px 70px;
}

.navbar_bottom {
    height: 6vh;
    margin: 0px 70px;
}

.navbar_logo-container {
    width: 20%;
}

.icons_container {
    width: 80%;
}

.icons_container img {
    // height: 25px;
    width: auto;
}


.navbar_icon {
    color: rgba(0,0,0,0.4);
    text-decoration: inherit;
}

.navbar_dorpdown-icon {
    position: relative;
    color: rgba(0,0,0,0.4);
    text-decoration: inherit;
    width: 140px;
}


.navbar_icon-subtext {
    font-size: 15px;
    color: #000000;
    font-family: 'GothamMedium';
}

.navbar_icon-subtext-selected {
    font-size: 15px;
    color: #51b7f3;
    font-family: 'GothamMedium';
}

.navbar_logout {
    color: #51b7f3;
}


.navbar_dropdown {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 1;
}

.navbar_dropdown-box {
    width: 100%;
    box-shadow: -2px -2px 10px rgba(0,0,0,0.05);
    padding-top: 1px;
}

.navbar_cart-quantity {
    position: absolute;
    top: 0px;
    left: 20px;
    width: 15px;
    height: 15px;
    background-color: #51b7f3;
    border-radius: 20px;
}