.order-product_item {
    border-top: rgba(0,0,0,0.1) 2px solid;
    height: 130px;
}

.order-product_album-background {
    background: url('../../images/newDesignImages/album-img.png');
    background-size: cover; 
    height: 100px;
    width: 107px;
}

.order-product_album-background img {
    object-fit: cover;
}

.order-product_magnet-background {
    background: url('../../images/newDesignImages/magnets-background-img.png');
    background-size: cover; 
    height: 100px;
    width: 107px;
}

.order-product_magnet-background img {
    object-fit: cover;
    border-radius: 5px;
}

.order-product_canvas-background {
    background: url('../../images/newDesignImages/canvas-back-img.png');
    background-size: cover; 
    height: 100px;
    width: 100px;
}

.order-product_canvas-background img {
    object-fit: cover;
}

.order-product_canvas-fame-img {
    object-fit: cover;
}

.order-product_ornament-background {
    background: url('../../images/newDesignImages/ornaments-background-img.png');
    background-size: cover; 
    height: 115px;
    width: 107px;
}

.order-product_ornament-background img {
    object-fit: cover;
    border-radius: 75px;
}

.order-product_accessory-img {
    object-fit: cover;
}
