.create-card {
    transition: box-shadow .3s;
    box-shadow: 0 0 4px rgba(33,33,33,.2);
}

.create-card:hover {
    box-shadow: 0 0 15px rgba(33,33,33,.4); 
    // color: #a00; 
}

.social-media-username {
    color: rgba(0,0,0,0.4);
    font-size: 14px;
}

.logout-social-media {
    color: white;
    background-color: dodgerblue;
    width: 90%;
    font-size: 12px;
    border-radius: 3px;
}

/////////////////////////////////////


.create-project_container {
    width: 100%;
    height: 100%;
}

.create-project_inner-container {
    background-color: white;
    height: 40%;
    min-height: 300px;
    max-height: 400px;
    min-width: 800px;
    max-width: 900px;
    width: 65%;
}

.create-project_top-container {
    height: 25%;
    width: 100%;
    background-color: #00baf1;
}


.create-project_sources-container {
    padding: 0px 150px;
    height: 75%;
}

.create-project_card-subtitle {
    text-align: center;
    width: 120%;
    font-family: 'GothamMedium';
    font-size: 15px;
}

.create-project_card:hover {
    border: #51B7F3 1.5px solid;
}
