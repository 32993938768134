$pixyalbum-main-color: #89C1C1;
$pixyalbum-main-button-color: #89C1C1;
$pixyalbum-button-color: #fff;
$pixyalbum-secondary-button-color: #6c757d;
$pixyalbum-tertiary-button-color: #C83C7D;
$pixyalbum-danger-button-color: #ff0000;
$facebook-color: #3B5998;
$pixyalbum-pixypesos-plus: #61afe1;
$pixyalbum-purple-color: #816ED9;
// Font
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap');
$main_font: 'Source Sans Pro',
sans-serif;
$theme-colors: ( "primary": #51B7F3);
@mixin box-sizing($box-model) {
    box-sizing: $box-model;
}

@import '~bootstrap/scss/bootstrap.scss';
html {
  @include box-sizing(border-box);
  height: 100%;
}

body {
  font-family: $main_font;
  height: 100%;
  overflow: hidden;
}

a:hover {
  text-decoration: none;
}

#root {
  height: 100%;
}

.main-app {
  min-height: 100%;
}

.header-wrapper {
  min-height: 4.5rem;
  width: 100%;
}

.container-main {
  flex: 1;
}

.container-main-aside {
  width: 90px;
}

.workspace-main-wrapper {
  flex: 1;
}

.container-main-workspace {
  flex: 1;
}

.container-main-create-button {
  height: 6rem;
  // align-items: center;
}

.scrollable-workspace {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.rounded-input {
  border-radius: 0.6rem;
}

.rounded-button {
  border-radius: 0.2rem;
}

.btn-pixyalbum-primary {
  @extend .btn;
  @include button-variant( $pixyalbum-main-button-color,
  $pixyalbum-main-button-color,
  darken($pixyalbum-main-button-color, 5%),
  darken($pixyalbum-main-button-color, 5%),
  darken($pixyalbum-main-button-color, 10%),
  darken($pixyalbum-main-button-color, 10%));
  color: $pixyalbum-button-color;
}

.btn-pixyalbum-primary:hover {
  opacity: 0.7;
  color: $pixyalbum-button-color;
}

.btn-pixyalbum-bottomless {
  @extend .btn;
  @include button-variant( $pixyalbum-button-color,
  $pixyalbum-main-button-color,
  darken($pixyalbum-button-color, 5%),
  darken($pixyalbum-button-color, 5%),
  darken($pixyalbum-button-color, 10%),
  darken($pixyalbum-button-color, 10%));
  color: $pixyalbum-main-button-color;
}

.btn-pixyalbum-bottomless:hover {
  background: white;
  color: $pixyalbum-secondary-button-color;
  border-color: $pixyalbum-main-button-color
}

.btn-pixyalbum-tertiary {
  @extend .btn;
  @include button-variant( $pixyalbum-tertiary-button-color,
  $pixyalbum-tertiary-button-color,
  darken($pixyalbum-tertiary-button-color, 5%),
  darken($pixyalbum-tertiary-button-color, 5%),
  darken($pixyalbum-tertiary-button-color, 10%),
  darken($pixyalbum-tertiary-button-color, 10%));
  color: $pixyalbum-button-color;
}

.btn-pixyalbum-tertiary:hover {
  opacity: 0.7;
  color: $pixyalbum-button-color;
  cursor: pointer;
}

.btn-pixyalbum-secondary {
  @extend .btn;
  @include button-variant( $pixyalbum-secondary-button-color,
  $pixyalbum-secondary-button-color,
  darken($pixyalbum-secondary-button-color, 5%),
  darken($pixyalbum-secondary-button-color, 5%),
  darken($pixyalbum-secondary-button-color, 10%),
  darken($pixyalbum-secondary-button-color, 10%));
}

.btn-pixyalbum-danger {
  @extend .btn;
  @include button-variant( $pixyalbum-danger-button-color,
  $pixyalbum-danger-button-color,
  darken($pixyalbum-danger-button-color, 5%),
  darken($pixyalbum-danger-button-color, 5%),
  darken($pixyalbum-danger-button-color, 10%),
  darken($pixyalbum-danger-button-color, 10%));
}

.btn-pixyalbum-border {
  @extend .btn;
  @include button-variant( $pixyalbum-button-color,
  $pixyalbum-main-button-color,
  darken($pixyalbum-button-color, 5%),
  darken($pixyalbum-button-color, 5%),
  darken($pixyalbum-button-color, 10%),
  darken($pixyalbum-button-color, 10%));
  color: $pixyalbum-main-color;
}

.btn-pixyalbum-border:hover {
  background: #FFF;
  color: $pixyalbum-main-color;
  border-color: $pixyalbum-main-color;
}

.btn-facebook {
  @extend .btn;
  @extend .btn-block;
  @include button-variant( $facebook-color,
  $facebook-color,
  darken($facebook-color, 5%),
  darken($facebook-color, 5%),
  darken($facebook-color, 10%),
  darken($facebook-color, 10%));
}

.btn-pixyalbum-plus-minus {
  @extend .btn;
  @include button-variant( $pixyalbum-main-button-color,
  $pixyalbum-main-button-color,
  darken($pixyalbum-main-button-color, 5%),
  darken($pixyalbum-main-button-color, 5%),
  darken($pixyalbum-main-button-color, 10%),
  darken($pixyalbum-main-button-color, 10%));
  color: $pixyalbum-button-color;
  height: 24px;
  width: 24px;
  margin: 0px;
  padding: 0em 0.45rem;
}

.btn-pixyalbum-plus-minus:hover {
  opacity: 0.7;
  color: $pixyalbum-button-color;
  cursor: pointer;
}

.container-pixyalbum {
  max-width: 1880px;
}

.text-pixyalbum {
  color: $pixyalbum-main-color;
}

.text-color-pink-pixy {
  color: $pixyalbum-tertiary-button-color;
}

.text-color-purple-pixy {
  color: $pixyalbum-purple-color;
}

.text-color-white{
  color: $pixyalbum-button-color;
}

.color-pixy {
  color: $pixyalbum-main-color;
}

.color-pixypesos-plus{
  color: $pixyalbum-pixypesos-plus;
}

.bg-pixy-pink{
  background: $pixyalbum-purple-color;
}

.cursor-pointer {
  cursor: pointer;
}

.border-null {
  border: 0px;
}

.border-null:hover {
  border: none;
}

.border-null:focus {
  border: 1px;
}

.border-null:focus,
input:focus,
input[type]:focus {
  border-color: none;
  outline: 0 none;
}

.icon-color-gray:hover {
  color: $pixyalbum-main-color !important;
}

.bg-color-pixyalbum {
  background-color: $pixyalbum-main-color;
}


/*
      FONTS
*/

.text-color-pixyalbum {
  color: $pixyalbum-main-color;
}

.title-semibold-24 {
  font-family: $main_font;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
}

.title-semibold-20 {
  font-family: $main_font;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
}

.title-semibold-18 {
  font-family: $main_font;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
}

.title-semibold-16 {
  font-family: $main_font;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
}

.title-semibold-16 {
  font-family: $main_font;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
}

.title-semibold-14 {
  font-family: $main_font;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}

.title-regular-18 {
  font-family: $main_font;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.title-regular-17 {
  font-family: $main_font;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
}

.title-regular-16 {
  font-family: $main_font;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.title-regular-14 {
  font-family: $main_font;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

.title-regular-13 {
  font-family: $main_font;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
}

.title-regular-12 {
  font-family: $main_font;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
}

.title-regular-10 {
  font-family: $main_font;
  font-weight: 400;
  font-style: normal;
  font-size: 11px;
}

////////////////////////


.generic-button-white {
  color: rgba(0,0,0,0.6);
  background-color: white;
  width: 220px;
  height: 40px;
  border: rgba(0,0,0,0.6) solid 1.5px;
  border-radius: 5px;
  font-family: 'GothamMedium';
  font-size: 15px;
}

.generic-button-white:hover {
  color: #51b7f3;
  background-color: white;
  width: 220px;
  height: 40px;
  border: #51b7f3 solid 1.5px;
  border-radius: 5px;
  font-family: 'GothamMedium';
  font-size: 15px;
}

.generic-button-white-2 {
  color: #51b7f3;
  background-color: white;
  width: 220px;
  height: 40px;
  border: #51b7f3 solid 1.5px;
  border-radius: 5px;
  font-family: 'GothamMedium';
  font-size: 15px;
}




.generic-button-blue {
  color: white;
  background-color: #51b7f3;
  width: 220px;
  height: 40px;
  border: hidden;
  border-radius: 3px;
  font-family: 'GothamMedium';
  font-size: 15px;
}

.generic-button-gray {
  color: white;
  background-color: #6E7781;
  opacity: 0.44;
  width: 220px;
  height: 40px;
  border: hidden;
  border-radius: 3px;
  font-family: 'GothamMedium';
  font-size: 15px;
}

@media(max-width: 1000px) {
  .generic-button-blue {
    color: white;
    background-color: #51b7f3;
    width: 150px;
    height: 30px;
    border: hidden;
    border-radius: 3px;
    font-family: 'GothamMedium';
    font-size: 12px;
  }
}


// .generic-button-blue:hover {
//   color: white;
//   background-color: #2594cc;
//   width: 220px;
//   height: 40px;
//   border: hidden;
//   border-radius: 3px;
//   font-family: 'GothamMedium';
//   font-size: 15px;
// }




.gray-btn {
  background-color: #626262;
  height: 50px;
  color: white;
}

@font-face {
  font-family: 'GothamBold';
  src: url(../utils/Fonts/GothamBold.ttf);
}

@font-face {
  font-family: 'GothamMedium';
  src: url(../utils/Fonts/GothamMedium.ttf);
}

@font-face {
  font-family: 'FuturaBookBold';
  src: url(../utils/Fonts/FuturaBookBold.ttf);
}

@font-face {
  font-family: 'FuturaMd';
  src: url(../utils/Fonts/FuturaMedium.ttf);
}

@font-face {
  font-family: 'FuturaBkBt';
  src: url(../utils/Fonts/FuturaBkBt.ttf);
}

@font-face {
  font-family: 'FuturaLtBt';
  src: url(../utils/Fonts/FuturaLtBt.ttf);
}

@font-face {
  font-family: 'FuturaModified';
  src: url(../utils/Fonts/FuturModBoo);
}

@font-face {
  font-family: 'GothamBook';
  src: url(../utils/Fonts/gotham-book.otf);
}

@font-face {
  font-family: 'GemunuSemiBold';
  src: url(../utils/Fonts/gemunu-semibold.ttf);
}

@font-face {
  font-family: 'GemunuRegular';
  src: url(../utils/Fonts/gemunu-regular.ttf);
}

.gemunu-semibold-white-20 {
  font-family: 'GemunuSemiBold';
  font-size: 20px;
  color: white;
}

.gotham-bold-white-15 {
  font-family: 'GothamBold';
  font-size: 15px;
  color: white;
}

.gotham-bold-white-18 {
  font-family: 'GothamBold';
  font-size: 18px;
  color: white;
}

.gotham-bold-20 {
  font-family: 'GothamBold';
  font-size: 20px;
  color: #000000
}

.gotham-bold-white-20 {
  font-family: 'GothamBold';
  font-size: 20px;
  color: white;
}

.gotham-bold-white-30 {
  font-family: 'GothamBold';
  font-size: 30px;
  color: white;
}

.gotham-bold-blue-15 {
  font-family: 'GothamBold';
  font-size: 15px;
  color: #51B7F3;
}

.gotham-md-blue-15 {
  font-family: 'GothamMedium';
  font-size: 15px;
  color: #51B7F3;
}

.gotham-bold-blue-14 {
  font-family: 'GothamBold';
  font-size: 14px;
  color: #51B7F3;
}

.gotham-bold-blue-18 {
  font-family: 'GothamBold';
  font-size: 18px;
  color: #00baf1;
}

.gotham-bold-purple-15 {
  font-family: 'GothamBold';
  font-size: 15px;
  color: #9F7CE9;
}

.gotham-bold-blue-20 {
  font-family: 'GothamBold';
  font-size: 20px;
  color: #00baf1;
}

.gotham-bold-25 {
  font-family: 'GothamBold';
  font-size: 25px;
}

.gotham-bold-blue-25 {
  font-family: 'GothamBold';
  font-size: 25px;
  color: #00baf1;
}

.gotham-bold-gray-20 {
  font-family: 'GothamBold';
  font-size: 20px;
  color: #6c757d;
}

.gotham-bold-darkgray-20 {
  font-family: 'GothamBold';
  font-size: 20px;
  color: #525353;
}

.gotham-bold-lightgray-14 {
  font-family: 'GothamBold';
  font-size: 14px;
  color: #929292;
}

.gotham-bold-darkgray-15 {
  font-family: 'GothamBold';
  font-size: 15px;
  color: #525353;
}
.gotham-md-darkgray-20 {
  font-family: 'GothamMedium';
  font-size: 20px;
  color: #262D33;
}

.gotham-bold-gray-18 {
  font-family: 'GothamBold';
  font-size: 18px;
  color: #525353;
}

.gotham-bold-gray-14 {
  font-family: 'GothamBold';
  font-size: 14px;
  color: #525353;
}

.gotham-bold-gray-15 {
  font-family: 'GothamBold';
  font-size: 15px;
  color: #929292;
}

.gotham-bold-lightgray-20 {
  font-family: 'GothamBold';
  font-size: 20px;
  color: rgba(0,0,0,0.4);
}

.gotham-bold-lightgray-18 {
  font-family: 'GothamBold';
  font-size: 18px;
  color: rgba(0,0,0,0.4);
}

.gotham-bold-lightgray-15 {
  font-family: 'GothamBold';
  font-size: 15px;
  color: rgba(0,0,0,0.4);
}

.gotham-bold-15 {
  font-family: 'GothamBold';
  font-size: 15px;
}

.gotham-bold-18 {
  font-family: 'GothamBold';
  font-size: 18px;
  color: #000000;
}

.gotham-md-15 {
  font-family: 'GothamMedium';
  font-size: 15px;
  color: #000000;
}

.gotham-md-14 {
  font-family: 'GothamMedium';
  font-size: 14px;
}


.gotham-md-12 {
  font-family: 'GothamMedium';
  font-size: 12px;
}

.gotham-md-gray-12 {
  font-family: 'GothamMedium';
  font-size: 12px;
  color: #6E7781;
}


.gotham-md-lightgray-6 {
  font-family: 'GothamMedium';
  font-size: 6px;
  color: #8D8D8D;
}

.gotham-md-lightgray-12 {
  font-family: 'GothamMedium';
  font-size: 12px;
  color: #8D8D8D;
}

.gotham-md-blue-12 {
  font-family: 'GothamMedium';
  font-size: 12px;
  color: #51B7F3;
}

.gotham-md-gray-13 {
  font-family: 'GothamMedium';
  font-size: 13px;
  color: rgba(0,0,0,0.6);
}

.gotham-md-gray-18 {
  font-family: 'GothamMedium';
  font-size: 18px;
  color: #525353;
}


.gotham-md-gray-20 {
  font-family: 'GothamMedium';
  font-size: 20px;
  color: #525353;
}

.gotham-md-lightgray-20 {
  font-family: 'GothamMedium';
  font-size: 20px;
  color: #929292;
}

.gotham-md-lightergray-20 {
  font-family: 'GothamMedium';
  font-size: 20px;
  color: #BDBDBD;
}


.gotham-md-lightgray-14 {
  font-family: 'GothamMedium';
  font-size: 14px;
  color: #929292;
}


.gotham-md-gray-25 {
  font-family: 'GothamMedium';
  font-size: 25px;
  color: #525353;
}

.gotham-md-gray-15 {
  font-family: 'GothamMedium';
  font-size: 15px;
  color: #525353;
}

.gotham-md-gray-17 {
  font-family: 'GothamMedium';
  font-size: 17px;
  color: #525353;
}

.gotham-md-lightgray-15 {
  font-family: 'GothamMedium';
  font-size: 15px;
  color: #6E7781;
}

.gotham-md-gray-10 {
  font-family: 'GothamMedium';
  font-size: 10px;
  color: #525353;
}

.gotham-md-gray-11 {
  font-family: 'GothamMedium';
  font-size: 11px;
  color: #525353;
}



.gotham-md-lightgray-15 {
  font-family: 'GothamMedium';
  font-size: 15px;
  color: #929292;
}

.gotham-md-white-15 {
  font-family: 'GothamMedium';
  font-size: 15px;
  color: white;
}

.gotham-md-white-18 {
  font-family: 'GothamMedium';
  font-size: 18px;
  color: white;
}


.gotham-book-15 {
  font-family: 'GothamBook';
  font-size: 15px;
  color: #000000
}

.gotham-book-18 {
  font-family: 'GothamBook';
  font-size: 18px;
}

.gotham-book-gray-22 {
  font-family: 'GothamBook';
  font-size: 22px;
  color: #525353;
}



.gotham-book-white-11 {
  font-family: 'GothamBook';
  font-size: 11px;
  color: white;
}

.gotham-book-white-18 {
  font-family: 'GothamBook';
  font-size: 18px;
  color: white;
}

.gotham-book-gray2-15 {
  font-family: 'GothamBook';
  font-size: 15px;
  color: #6E7781;
}

.gotham-book-gray-15 {
  font-family: 'GothamBook';
  font-size: 15px;
  color: #525353;
}

.gotham-book-gray-17 {
  font-family: 'GothamBook';
  font-size: 17px;
  color: #525353;
}

.gotham-book-darkgray-18 {
  font-family: 'GothamBook';
  font-size: 18px;
  color: #525353;
}

.gotham-book-darkgray-20 {
  font-family: 'GothamBook';
  font-size: 20px;
  color: #525353;
}

.gotham-book-blue-18 {
  font-family: 'GothamBook';
  font-size: 18px;
  color: #51B7F3;
}

.gotham-md-17 {
  font-family: 'GothamMedium';
  font-size: 17px;
}

.gotham-md-18 {
  font-family: 'GothamMedium';
  font-size: 18px;
  color: #000000;
}

.gotham-md-20 {
  font-family: 'GothamMedium';
  font-size: 20px;
}


.gotham-book-18 {
  font-family: 'GothamBook';
  font-size: 18px;
}

.gotham-book-12 {
  font-family: 'GothamBook';
  font-size: 12px;
}

.gotham-book-13 {
  font-family: 'GothamBook';
  font-size: 13px;
}

.gotham-book-15 {
  font-family: 'GothamBook';
  font-size: 15px;
  color: #000000;
}

.2 {
  font-family: 'GothamBook';
  font-size: 15px;
  color: #929292;
}

.futura-bold-15 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 15px;
} 

.futura-bold-gray-15 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 15px;
  color: rgba(0,0,0,0.5);
} 

.futura-bold-gray-20 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 20px;
  color: rgba(0,0,0,0.5);
} 

.futura-bold-17 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 17px;
} 

.futura-bold-18 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 18px;
} 

.futura-bold-white-18 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 18px;
  color: white;
}

.futura-bold-20 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 20px;
} 

.futura-bold-25 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 25px;
} 

.futura-bold-white-25 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 25px;
  color: white;
} 

.futura-bold-white-30 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 30px;
  color: white;
} 

.futura-bold-gray-25 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 25px;
  color: rgba(0,0,0,0.5);
} 

.futura-bold-blue-25 {
  font-family: 'FuturaMd';
  font-weight: bold;
  font-size: 25px;
  color: #00baf1;
} 

.futura-bkbt-15 {
  font-family: 'FuturaBkBt';
  font-size: 15px;
}

.futura-bkbt-13 {
  font-family: 'FuturaBkBt';
  font-size: 13px;
}

.futura-bkbt-gray-12 {
  font-family: 'FuturaBkBt';
  font-size: 12px;
  color: rgba(0,0,0,0.6);
}

.futura-bkbt-lightgray-12 {
  font-family: 'FuturaBkBt';
  font-size: 12px;
  color: rgba(0,0,0,0.4);
}

.futura-bkbt-gray-15 {
  font-family: 'FuturaBkBt';
  font-size: 15px;
  color: #999999;
}

.futura-bkbt-gray-18 {
  font-family: 'FuturaBkBt';
  font-size: 18px;
  color: #999999;
}

.futura-bkbt-blue-15 {
  font-family: 'FuturaBkBt';
  font-size: 15px;
  color: #00baf1;
}

.futura-bkbt-17 {
  font-family: 'FuturaBkBt';
  font-size: 17px;
}

.futura-bkbt-18 {
  font-family: 'FuturaBkBt';
  font-size: 18px;
}

.futura-bkbt-blue-18 {
  font-family: 'FuturaBkBt';
  font-size: 18px;
  color: #00baf1;
}

.futura-bkbt-20 {
  font-family: 'FuturaBkBt';
  font-size: 20px;
}

.futura-bkbt-white-20 {
  font-family: 'FuturaBkBt';
  font-size: 20px;
  color: white;
}

.futura-ltbt-15 {
  font-family: 'FuturaLtBt';
  font-size: 15px;
}

.futura-ltbt-gray-15 {
  font-family: 'FuturaLtBt';
  font-size: 15px;
  color: rgba(0,0,0,0.5);
}


.futura-md-15 {
  font-family: 'FuturaMd';
  font-size: 15px;
}

.futura-md-13 {
  font-family: 'FuturaMd';
  font-size: 13px;
}

.futura-md-lightgray-13 {
  font-family: 'FuturaMd';
  font-size: 13px;
  color: rgba(0,0,0,0.4);
}

.futura-md-blue-13 {
  font-family: 'FuturaMd';
  font-size: 13px;
  color: #00baf1;
}

.futura-md-white-15 {
  font-family: 'FuturaMd';
  font-size: 15px;
  color: white;
}

.futura-md-17 {
  font-family: 'FuturaMd';
  font-size: 17px;
}

.futura-md-20 {
  font-family: 'FuturaMd';
  font-size: 20px;
}

.futura-md-25 {
  font-family: 'FuturaMd';
  font-size: 25px;
}

.futura-md-30 {
  font-family: 'FuturaMd';
  font-size: 30px;
}

.futura-md-lightgray-15 {
  font-family: 'FuturaMd';
  font-size: 15px;
  color: rgba(0,0,0,0.4);
}

.futura-md-gray-15 {
  font-family: 'FuturaMd';
  font-size: 15px;
  color: rgba(0,0,0,0.6);
}

.futura-md-18 {
  font-family: 'FuturaMd';
  font-size: 18px;
}

.futura-md-blue-18 {
  font-family: 'FuturaMd';
  font-size: 18px;
  color: #00baf1;
}

.futura-md-white-18 {
  font-family: 'FuturaMd';
  font-size: 18px;
  color: white;
}


.futura-md-gray-18 {
  font-family: 'FuturaMd';
  font-size: 18px;
  color: rgba(0,0,0,0.4);
}


.futura-md-gray-20 {
  font-family: 'FuturaMd';
  font-size: 20px;
  color: rgba(0,0,0,0.4);
}

.futura-md-blue-15 {
  font-family: 'FuturaMd';
  font-size: 15px;
  color: #00baf1;
}

.futura-md-blue-14 {
  font-family: 'FuturaMd';
  font-size: 14px;
  color: #51B7F3;
}

.futura-md-blue-17 {
  font-family: 'FuturaMd';
  font-size: 17px;
  color: #00baf1;
}

.futura-md-blue-20 {
  font-family: 'FuturaMd';
  font-size: 20px;
  color: #00baf1;
}

.gotham-bold-pink-25 {
  font-family: 'GothamBold';
  font-size: 25px;
  color: #DD5FB0;
}

.gotham-bold-green-30 {
  font-family: 'GothamBold';
  font-size: 30px;
  color: #60B7C7;
}


.gotham-bold-green-16 {
  font-family: 'GothamBold';
  font-size: 16px;
  color: #3BB0AB;
}

.gotham-bold-pink-16 {
  font-family: 'GothamBold';
  font-size: 16px;
  color: #E53980;
}

.gotham-bold-black-23 {
  font-family: 'GothamBold';
  font-size: 23px;
  color: #000000;
}

////////////////////

.gotham-bold-black-md {
  font-family: 'GothamBold';
  font-size: 18px;
  color: #000000;
}

.gotham-md-lightgray {
  font-family: 'GothamMedium';
  font-size: 15px;
  color: #929292;
}

@media(max-width: 1100px) {
  .gotham-bold-black-md {
    font-family: 'GothamBold';
    font-size: 15px;
    color: #000000;
  }

  .gotham-md-lightgray {
    font-family: 'GothamMedium';
    font-size: 12px;
    color: #929292;
  }
}

