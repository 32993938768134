.square-image {
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
}
  
.check-container {
  width: 25px;
  height: 25px;
}

.label-square:before {
  content: url(../../images/img_unchecked.png);
  position: absolute;
}

:checked + .label-square:before {
  content: url(../../images/img_checked.png);
  position: absolute;
}

.square-input[type=checkbox] {
  display: none;
  position: absolute;
}

.label-square {
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
}