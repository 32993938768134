bg-image-book {
  position:absolute;
  height: 0;
  padding-top: 100%;
  color: white;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-page-image-order {
  height: 0;
  padding-top: 100%;
  color: white;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}


/////////////////////

.orders_container {
  background: url('../../images/newDesignImages/background-orders.jpg');
  object-fit: cover;
}

.orders_sm-container {
  background: url('../../images/newDesignImages/background-orders.jpg');
  object-fit: cover;
  height: 45vh;
}

.orders_inside-container {
  width: 70%;
  background-color: white;
  border: rgba(0,0,0,0.1) 2px solid;
  border-radius: 20px;
}

.orders_title {
  border-bottom: rgba(0,0,0,0.1) 2px solid;
}

.order_card-date {
  background-color: rgba(0,0,0,0.03);
}

.order_order-number {
  background-color: #999999;
  height: 35px;
  width: 300px;
  color: white;
}

.order_status {
  width: 180px;
}