.contentDetailPhoto {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.card-photo-detail {
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 5%;
    height: 35vw;
    width: 35vw;
}

.text-date-detail-photo {
    color: #919aa3;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: 12px;
    margin-right: -10px;
}

$font-size: 0.8vw;
$line-height: 1;
$lines: 3;
.text-date-detail-top {
    display: block;
    display: -webkit-box;
    height: $font-size * $line-height * $lines;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    position: absolute;
    margin-top: -1.2vw;
    margin-left: 23vw;
}

.text-date-detail-footer {
    display: block;
    display: -webkit-box;
    height: $font-size * $line-height * $lines;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.container-detail-photo {
    margin: 10px auto;
    width: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.container-column-detail-photo {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.arrow-previous {
    margin-left: 10%;
    left: 5px;
}

.arrow-next {
    margin-right: 10%;
    right: 5px;
}

.arrow-previous:hover {
    color: blue;
    cursor: pointer;
}

.arrow-next:hover {
    color: blue;
    cursor: pointer;
}

.textarea-none {
    resize: none;
}

@media (max-width: 414px) {
    .btn-pixyalbum-primary {
        margin-right: 20px !important;
    }
}


/////////////////////////

// .photo-detail_container {
//     height: 100%;
//     width: 100%;
// }

// .photo-detail_inside-container {
//     height: 70%;
//     width: 75%;
//     min-width: 700px;
//     min-height: 500px;
// }

.photo-detail_inside-top {
    height: 7%;
    width: 100%;
}

.photo-detail_inside-bottom {
    background-color: white;
    height: 93%;
    width: 100%;
    border-radius: 20px;
}

.photo-detail_image-container {
    width: 50%;
}

.photo-detail_edit-container {
    width: 50%;
}

.photo-detail_image {
    width: 90%;
    height: 90%;
}

.photo-detail_image img {
    object-fit: contain;
}

.photo-detail_textimage {
    width: 90%;
}

// .photo-detail_underimagetext {
//     font-family: 'GothamMedium';
//     font-size: 12px;
//     color: rgba(0,0,0,0.6);
//     text-align: center;
// }


// .photo-detail_edit-date {
//     // border: rgba(0,0,0,0.6) 1px solid;
//     border: rgba(0,0,0,0.4) 2px solid;
//     height: 50px;
//     width: 200px;
//     border-radius: 15px;
//     color: rgba(0,0,0,0.4);
//     font-size: 18px;
//     padding: 0px 20px;
// }

// .photo-detail_date-container {
//     // background-color: lightblue;
// }


// .photo-detail_comment {
//     border: rgba(0,0,0,0.2) 1px solid;
//     border-radius: 20px;
// }

// .photo-detail_comment textarea {
//     width: 100%;
//     height: 75px;
//     border-radius: 20px;
//     border: none;
//     resize: none;
//     outline: none;
//     color: #626262;
// }

.photo-detail_submit-btn {
    background-color: rgba(0,0,0,0.6);
    border: hidden;
    height: 50px;
}


/////////////////////////

.photo-detail_container {
    height: 100%;
    width: 100%;
    z-index: 3;
}

.photo-detail_inside-container {
    position: relative;
    height: 93%;
    width: 50%;
    min-width: 700px;
    min-height: 500px;
    background-color: white;
}


.photo-detail_close-img {
    position: absolute;
    right: 10px;
    top: 10px
}

.photo-detail_img-container {
    height: 70%;
    width: 70%;
}

.photo-detail_img {
    object-fit: contain;
}


.photo-detail_center {
    // position: relative;
    height: 60%;
    width: 100%;
    // background-color: gray;
}

.photo-detail_photo-frame {
    width: 400px;
    height: 400px;
}

.photo-detail_bottom {
    height: 40%;
    width: 100%;
    // background-color: lightblue;
}

.photo-detail_edit-items {
    height: 40%;
    width: 100%;
    // background-color: gray;
}

.photo-detail_edit {
    height: 40%;
    width: 100%;
    // background-color: rgba(0,0,0,0.1);
}

.photo-detail_save-cancel-buttons {
    height: 20%;
    width: 100%;
}

.photo-detail_crop-container {
    position: relative;
    width: 400px;
    height: 400px;
}

.photo-detail_rotate-btn {
    position: absolute;
    top: 40%;
    left: 45%;
    // bottom: 50%;
    // right: 50%;
    z-index: 1;
    height: 70px;
    width: auto;
}

.photo-detail_slider {
    padding: 0px 20%;
}

.photo-detail_date-container {
    // background-color: lightblue;
    width: 70%; 
    height: 12%;
}

.photo-detail_comment-container {
    // background-color: lightblue;
    width: 80%;
    height: 18%;
}

.photo-detail_underimagetext {
    font-family: 'GothamMedium';
    font-size: 11px;
    color: rgba(0,0,0,0.6);
    text-align: center;
}

.photo-detail_edit-date {
    // border: rgba(0,0,0,0.6) 1px solid;
    border: rgba(0,0,0,0.2) 1px solid;
    height: 40px;
    width: 200px;
    border-radius: 10px;
    color: #525353;
    font-size: 18px;
    padding: 0px 20px;
}

.photo-detail_edit-comment-container {
    // background-color: rgba(0,0,0,0.2);
    width: 50%;
}

.photo-detail_comment {
    border: rgba(0,0,0,0.2) 1px solid;
    border-radius: 10px;
    height: 70%;
}

.photo-detail_comment textarea {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: none;
    resize: none;
    outline: none;
    color: #626262;
}


.photo-detail_restore {
    width: 400px;
}

.photo-detail_restore button {
    border: hidden;
    background-color: white;
    font-family: 'GothamMedium';
    color: #929292;
    font-size: 14px;
}

.photo-detail_warning {
    height: 10%;
    width: 100%;
    padding-bottom: 15px;
}

@media(max-height: 765px) {
    .photo-detail_inside-container {
        position: relative;
        height: 98%;
        width: 50%;
        min-width: 700px;
        min-height: 500px;
        background-color: white;
    }

    .photo-detail_crop-container {
        position: relative;
        width: 300px;
        height: 300px;
    }

    .photo-detail_restore {
        width: 300px;
    }

    .photo-detail_photo-frame {
        width: 300px;
        height: 300px;
    }

    .photo-detail_underimagetext {
        font-family: 'GothamMedium';
        font-size: 9px;
        color: rgba(0,0,0,0.6);
        text-align: center;
    }
}