.incomplete-color {
  color: #B11B1B;
}

.complete-color {
  color: #1BB11B;
}

.images-container {
  overflow: scroll;
  max-height: 60vh;
}

//////////////////////

.image-picker_loading {
  height: 60vh;
}

.image-picker_header {
  background-color: rgba(0,0,0,0.03);
  height: 9vh;
}


.image-picker_create-btn {
  width: 30%;
  height: 50px;
  color: white;
  border: hidden;
  background-color:  #00baf1;
}
