// .sortable-item_page {
//     position: relative;
// }

.sortable-item_repeated-left {
    position: absolute;
    top: -18%;
    left: 25%;
}

.sortable-item_repeated-right {
    position: absolute;
    top: -18%;
    left: 10%;
}


@media(max-width: 1200px) {
    .sortable-item_repeated-left {
        position: absolute;
        top: -15%;
        left: 30%;
    }
    .sortable-item_repeated-right {
        position: absolute;
        top: -15%;
        left: 25%;
    }
}

@media(max-width: 991px) {
    .sortable-item_repeated-left {
        position: absolute;
        top: -10%;
        left: 35%;
    }
    .sortable-item_repeated-right {
        position: absolute;
        top: -10%;
        left: 25%;
    }
}

///////

.sortable-item_main {
    height: 100%;
    width: 100%;

}

.sortable-item_vertical-space {
    width: 85%;
    height: 12.5%;
    // background-color: blue;
}

.sortable-item_page {
    position: relative;
    height: 75%;
    width: 85%;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: white;
}

.sortabl-item_check-icon {
    width: 20%;
    height: 20%;
    border-radius: 100%;
    position: absolute;
    right: 6%;
    top: 12.5%;
    z-index: 1;
}

.sortable-item_delete {
    position: absolute;
    top: -7%;
    left: 0;
}

.sortable-item_page-date {
    width: 75%;
    height: 12.5%;
    padding-bottom: 2%;
}

.sortable-item_page-comment {
    width: 80%;
    height: 12.5%;
    padding-top: 2%;
}

.sortable-item_page-img {
    position: relative;
    width: 75%;
    height: 75%;
}

.sortable-item_img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.sortable-item_warning-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    // width: 20px;
    height: 30%;
    // background-color: lightblue;
}

.sortable-item_warning-item {
    width: 90%;
    height: 45%;
    background-color: white;
    opacity: 0.8;
    border-radius: 3px;
    margin: 2% 0;
}

.sortable-item_warning-text {
    font-family: 'GothamMedium';
    font-size: 10px;
    color: #525353;
}

.sortable-item_page-img-hover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}


.sortable-item_comment-date-text {
    font-family: 'GothamMedium';
    font-size: 3px;
    color: #8D8D8D;
    text-align: center;
}

@media(max-width: 1200px) {
    .sortable-item_comment-date-text {
        font-family: 'GothamMedium';
        font-size: 2px;
        color: #8D8D8D;
        text-align: center;
    }
}

@media(min-width: 1450px) {
    .sortable-item_comment-date-text {
        font-family: 'GothamMedium';
        font-size: 4px;
        color: #8D8D8D;
        text-align: center;
    }
}

@media(max-width: 1400px) {
    .sortable-item_warning-text {
        font-family: 'GothamMedium';
        font-size: 8px;
        color: #525353;
    }
}


