.square {
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
}

.logo {
    cursor: pointer
}

.text-muted {
    color: #919aa3;
    font-weight: 100;
}

.f-w-100 {
    font-weight: 100;
    color: #222000!important;
    padding: 3px;
}

.f-w-350 {
    font-weight: 350;
    color: #222000!important;
    padding: 5px;
}

p,
hr,
h6 {
    margin: 0;
    padding: 0;
}

.col-12 {
    margin: 10px;
}

Link {
    text-decoration: none;
}

.collection-wrapper {
    display: block;
}

.btn-delete-collection {
    z-index: 1;
    opacity: 0;
    transition: 0.3s;
    cursor: pointer;
}

.collection-wrapper:hover .btn-delete-collection {
    opacity: 1;
}

.edit-title {
    opacity: 0;
    z-index: 2;
    left: 20%;
    margin-top: -87px;
    cursor: pointer;
}

.collection-wrapper:hover .edit-title {
    opacity: 1;
}

.edit-title:hover {
    color: blue;
    font-size: 18px;
}

.icon-color {
    cursor: pointer;
    color: #89C1C1;
}

.icon-color:hover .t-color {
    color: #89C1C1;
}

.t-color {
    color: #919aa3;
    font-family: 'Source Sans Pro', sans-serif;
}

.cursor-pointer {
    cursor: pointer;
}

.hide-delete {
    opacity: 0;
}

.collection-wrapper:hover .hide-delete {
    opacity: 1;
}


////////////////////////////////

// .card_main-container {
//     height: 490px;
//     background-color: aliceblue;
// }

// .card_container {
//     width: 350px;
// }

// .card_top-container {
//     background-color: white;
//     padding: 20px 0px;
//     height: 110px;
// }

// .card_img-container {
//     background-image: url('../../images/newDesignImages/album-img.png');
//     background-size: cover; 
//     height: 330px;
//     width: 350px;
// }


// .card_img-container-standard {
//     background-color: white;
//     border-top: rgba(0,0,0,0.08) solid 1px;
//     height: 330px;
//     width: 350px;
// }


.card_img-container-inside-magnet {
    background-image: url('../../images/newDesignImages/magnets-background-img.png');
    background-size: cover; 
    height: 250px;
    width: 280px;
}

.card_img-container-inside-magnet img {
    border-radius: 13px;
}

// .card_img-container-inside-canvas {
//     background-image: url('../../images/newDesignImages/canvas-back-img.png');
//     background-size: cover; 
//     height: 280px;
//     width: 280px;
// }

// .card_img {
//     object-fit: cover;
// }

.card_img-predesign {
    object-fit: cover;
    opacity: 0.85;
}

// .card_delete-container {
//     width: 60px;
//     height: 60px;
//     border: #d3d8dd 3px solid;
//     border-radius: 15px;
//     background-color: white;
// }

//////////////////

.card_main-container {
    width: 25%;
    height: calc(100vw * 16/50);
}

.card_container {
    width: 95%;
    height: 80%;
    background-color: #F0F8FF;
}

.card_top-container {
    background-color: white;
    padding: 20px 0px;
    height: 30%;
}

.card_img-container {
    background-color: white;
    width: 100%;
    height: 70%;
}

.card_album {
    width: 70%;
    height: calc(100vw * 15/100);
}

.card_album-left {
    width: 5%;
    height: 100%;
    box-shadow: 1px 0 5px -2px #888;
}

.card_album-right {
    width: 95%;
    height: 100%;
}

.card_img {
    object-fit: cover;
}

.card_img-magnet {
    object-fit: cover;
    border-radius: 10px;
}

.collection_card_img-ornament {
    object-fit: cover;
    border-radius: 125px;
}

.card_album-img {
    width: 100%;
    height: 100%;
    
}

.card_magnet {
    width: 80%;
    height: calc(100vw * 16/100);
    background-image: url('../../images/newDesignImages/magnets-background-img.png');
    background-size: cover;
}

.card_canvas {
    width: 73%;
    height: calc(100vw * 16/100);
    box-shadow: 8px 3px 5px rgba(0,0,0,0.28);
}

.collection_card_ornament {
    width: 75%;
    height: calc(100vw * 18/100);
    background-image: url('../../images/newDesignImages/ornaments-background-img.png');
    background-size: cover;
}

.card_delete-container {
    width: 18%;
    height: calc(100vw * 4/100);
    border: #d3d8dd 3px solid;
    border-radius: 15px;
    background-color: white;
}