.empty-cart-main {
    height: 85vh;
}

.empty-cart-btn {
    width: 300px;
    height: 50px;
    border: hidden;
    border-radius: 5px;
    background-color: #51B7F3;
}

.empty-cart_img {
    height: 60%;
}