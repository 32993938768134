.accessories-cart_main {
    width: 100%;
}

.accessories-cart_inside {
    width: 95%;
    border-top: rgba(0,0,0,0.1) 2px solid;
}

.accessories-cart_items {
    width: 100%;
    // height: 30vw;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}