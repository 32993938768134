.input-cupon {
    width: 200%;
}

.voucher-input {
    padding-left: 10px;
    height: 50px;
    width: 85%;
    border: hidden;
    color: #626262;
    font-size: 15px;
    font-family: 'FuturaMd';
}

.voucher_added-voucher {
    height: 50px;
}

/////////////////
.voucher_input-button-container {
    width: 100%;
}

.voucher_input-container {
    width: 80%;
    height: 50px;
    border: #51B7F3 1px solid;
}

.voucher_input {
    height: 45px;
    width: 80%;
    border: hidden;
    color: #525353;
}

.voucher_apply-cancel-button {
    width: 20%;
    height: 50px;
    background-color: #51B7F3;
    border: hidden;
}