.cover-card_main {
    width: 50%;
    height: calc(90vw/4);
}

.cover-card {
    width: 90%;
    height: 90%;
    border: 1px solid;
    border-color: rgba(0,0,0,0.1);
    box-shadow: 2px 2px rgba(0,0,0,0.05);
}

.cover-card_img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.cover-card_img:hover {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border: #51b7f3 4px solid;
}