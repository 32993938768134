.image-card {
    height: 50%;
    width: 45%;
}

.image-check {
    height: 30%;
    width: 25%;
}

.card-row {
    width: 100%;
    background-color: lightblue;
    display: flex;
    justify-content: space-between;
}

.card-container {
    display: flex;
    justify-content: space-between;
}