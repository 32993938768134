.inside-cover_title {
    font-family: 'GothamMedium';
    font-size: 15px;
    color: #6E7781;
}

.inside-cover_subtitle {
    font-family: 'GothamMedium';
    font-size: 12px;
    color: #6E7781;
}

@media(max-width: 1200px) {
    .inside-cover_title {
        font-family: 'GothamMedium';
        font-size: 12px;
        color: #6E7781;
    }
    
    .inside-cover_subtitle {
        font-family: 'GothamMedium';
        font-size: 9px;
        color: #6E7781;
    }
}