.order-success_main {
    height: 85vh;
    width: 100%;
    overflow-y: scroll;
}

.order-success_confeti {
    position: relative;
    height: 250px;
    width: 400px;
    margin-bottom: 110px;
}

.order-success_confeti img {
    position: absolute;
    top: 150px;
    left: 8%;
}

.order-success_title {
    z-index: 1;
    margin-top: -10px;
    margin-bottom: -130px;
}

