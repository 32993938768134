.config-bar_default {
    position: absolute;
    top: 5%;
    left: 70px;
    height: 300px;
    z-index: 1;
}

.config-bar_reorder {
    background-color: #F9F9F9;
    height: 8vh;
    padding-right: 70px;
    position: fixed;
    top: 23vh;
    z-index: 3;
}

.config-bar_delete {
    background-color: #4FACE2;
    height: 8vh;
}

.config-bar_gear-icon {
    color: #51B7F3;
}

.config-bar_close-reorder {
    background-color: #8CD1FA;
    width: 80px;
    height: 100%;
}

.config-bar_delete-left {
    height: 100%;
}

.config-bar_option-btn {
    border: #51b7f3 solid 1.5px;
    border-radius: 5px;
    padding: 8px 50px;
    padding-right: 50px;
    padding-left: 50px;
    background-color: white;
    font-family: 'GothamMedium';
    font-size: 15px;
    color: #51B7F3;
}

.config-bar_option-btn-v2 {
    border: #51b7f3 solid 1.5px;
    border-radius: 5px;
    height: 40px;
    // padding: 10px 50px;
    padding-right: 50px;
    padding-left: 50px;
    background-color: white;
    font-family: 'GothamMedium';
    font-size: 13px;
    color: #51B7F3;
}

.config-bar_delete-btn {
    border: hidden;
    border-radius: 10px;
    height: 40px;
    width: 180px;
    background-color: #8CD1FA;
    font-family: 'GothamMedium';
    font-size: 15px;
    color: white;
    margin-right: 70px;
}

.config-bar_default-item {
    font-family: 'GothamBook';
    font-size: 15px;
    text-align: center;
}

@media(max-width: 1450px) {
    .config-bar_option-btn-v2 {
        border: #51b7f3 solid 1.5px;
        border-radius: 5px;
        height: 40px;
        padding-right: 50px;
        padding-left: 50px;
        background-color: white;
        font-family: 'GothamMedium';
        font-size: 11px;
        color: #51B7F3;
    }
}

@media(max-width: 1052px) {
    .config-bar_option-btn-v2 {
        border: #51b7f3 solid 1.5px;
        border-radius: 5px;
        height: 40px;
        padding-right: 50px;
        padding-left: 50px;
        background-color: white;
        font-family: 'GothamMedium';
        font-size: 9px;
        color: #51B7F3;
    }
}