.dragndrop-item_main {
    width: 95%;
    border-bottom: #C4C4C4 1px solid;
}


.dragndrop-item_show-img {
    height: 30px;
    width: 30px;
    object-fit: cover;   
}