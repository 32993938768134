.product-info_main {
    
}

.product-info_img {
    width: 90%;
    height: 90%;
    object-fit: cover;
}

.product-info_right {
    padding-right: 150px;
}

.product-info_right-inside {

}


.product-info_divition-line {
    border: #EEEEEE solid 1px;
}

.porduct-info_start-btn {
    margin: 30px 0px;
    width: 100%;
    height: 50px;
    color: white;
    border: hidden;
    background-color:  #00baf1;
}

.product-info_description {
    font-family: 'GothamBook';
    font-size: 15px;
    color: #525353;
    white-space: pre-line;
}

.product-info_album-img {
    object-fit: cover;
    margin: 40px;
    height: 500px;
    width: 600px;
}
