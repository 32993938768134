.login-card {
    max-width: 450px;
    min-width: 400px;
}

.login-main-wrapper {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url('../../images/SignIn.jpg');
    background-size: cover;
    background-position: center center;
}

.logo {
    color: #C83C7D;
}

.login_left {
    width: 55%;
    height: 100%;
    background: url('../../images/newDesignImages/login-img.png');
    background-size: cover;
    background-position: center;
}

.login_right {
    width: 45%;
    height: 100%;
    // background-color: lightblue;
}

.signin_password-forget {
    // text-decoration: none;
    font-family: 'GothamBook';
    font-weight: bold;
    font-size: 15px;
    color: #51B7F3;
}