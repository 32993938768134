.album-config_container {
    width: 100%;
    height: 100%;
}

.album-config_inside {
    position: relative;
    width: 600px;
    height: 500px;
    background-color: white;
    // min-width: 600px;
    border-radius: 20px;
    padding: 0px 100px;
}

.album-config_text {
    width: 120px;
    min-width: 120px;
}

.album-config_text-dates {
    width: 180px;
    min-width: 180px;
}

.album-config_unselected-option {
    height: 40px;
    width: 45%;
    border-radius: 10px;
    border: hidden;
    background-color: #F3F5F7;
    font-family: 'GothamMedium';
    font-size: 17px;
    color: #929292;
}

.album-config_selected-option {
    height: 40px;
    width: 45%;
    border-radius: 10px;
    border: #51B7F3 solid 2px;
    background-color: white;
    font-family: 'GothamMedium';
    font-size: 17px;
    color: #51B7F3;
}

.album-config_unselected-pages {
    height: 40px;
    width: 30%;
    border-radius: 10px;
    border: hidden;
    background-color: #F3F5F7;
    font-family: 'GothamMedium';
    font-size: 17px;
    color: #929292;
}

.album-config_selected-pages {
    height: 40px;
    width: 30%;
    border-radius: 10px;
    border: #51B7F3 solid 2px;
    background-color: white;
    font-family: 'GothamMedium';
    font-size: 17px;
    color: #51B7F3;
}


.album-config_loading-option {
    height: 40px;
    width: 45%;
    border-radius: 10px;
    border: hidden;
    background-color: #F3F5F7;
    font-family: 'GothamMedium';
    font-size: 17px;
    color: white;
}

.album-config_save-btn {
    height: 40px;
    width: 100%;
    border-radius: 10px;
    border: hidden;
    background-color: #51B7F3;
    font-family: 'GothamMedium';
    font-size: 17px;
    color: white;
}
