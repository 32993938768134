.from-album-item_main {
    width: 25%;
    height: calc(90vw/4);
}

.from-album-item_img {
    height: 90%;
    width: 90%;
    object-fit: cover;
}

.from-album-item_img:hover {
    height: 90%;
    width: 90%;
    object-fit: cover;
    border: #51b7f3 4px solid;
}