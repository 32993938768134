
.regular-popup_cancel-btn {
    width: 200px;
    height: 40px;
    border-radius: 3px;
    background-color: white;
    border: 1px solid rgb(104, 209, 169);
    color: rgb(104, 209, 169);
    font-family: 'GothamMedium';
    font-size: 15px;
}

.failure-popup_continue-btn {
    width: 200px;
    height: 40px;
    border-radius: 3px;
    background-color: rgb(104, 209, 169);
    border: hidden;
    color: white;
    font-family: 'GothamMedium';
    font-size: 15px;
}