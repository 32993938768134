.banner_main-container {
    // height: calc(100vw * 0.33);
    // height: calc(100vh * 0.5);
    height: calc(max(100vw * 0.33, 100vh * 0.5));
    width: 100%;
    padding: 0px 70px;
    background-position: center;
}

.banner_main-container button {
    position: relative;
    left: 75%;
    top: 40%;
    width: 200px;
    height: 50px;
    border: hidden;
    border-radius: 30px;
    background-color: #00baf2;
    color: white;
}

.banner_central {
    height: 100%;
    width: 100%;
}

.banner_selection-icons {
    width: 15%;
}