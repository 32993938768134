.accessory-detail_main {
    overflow-y: scroll;
    height: 85vh;
}

.accessory-detail_img-container {
    // border: black 1px solid;
    width: 50%;
    height: 30vw
}

.accessory-detail_img {
    width: 96%;
    height: 96%;
    object-fit: cover;
}

.accessory-detail_left {
    height: 85vh;
    overflow-y: scroll;
    padding-left: 70px;
}