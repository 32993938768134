.accessories-cart-item {
    width: 25%;
    // height: 30vw;
    flex: 0 0 auto;
}

.accessories-cart-item_img-container {
    width: 95%;
    height: 21vw;
}

.accessories-cart-item_data {
    // height: 30%;
    position: relative;
}

.accessories-card-item_btn {
    position: absolute;
    top: -25px;
    right: 25px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: #FFFFFF;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
}

.accessories-cart_text {
    font-family: 'GothamMedium';
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
} 