@media (max-width: 424px) {
    .container-main-create-button {
        height: 4rem;
    }
}

///////////////////////////


.collections-main_container {
    margin: 0px;
}

.collections_available-products-inside {
    width: 95%;
}

.create-project-button_container {
    height: 300px;
    font-family: 'FuturaMd';
}

.collections_container {
    background-color: #F0F8FF;
    height: auto;
    width: 100%;
    padding: 100px 0px;
}

.collections_customer-products {
    width: 100%;
}

.collections_customer-products-inside {
    width: 95%;
}

// .collections_accessories {
//     width: 95%;
//     height: 450px;
//     border-radius: 40px 40px 0px 0px;
// }

.collections_accessories {
    position: relative;
    background-image: url('../../images/newDesignImages/banner-accessories.png');
    background-size: cover;
    width: 95%;
    height: 45vw;
}

.collections_accessories-top {
    position: relative;
    width: 100%;
    height: 70%;
    background-color: #F7F9FA;
    border-radius: 40px 40px 0px 0px;
}

.collections_accessories-newtag {
    position: absolute;
    top: 0;
    right: 70px;
    width: 150px;
    height: 85px;
    background-color: #51B7F3;
    border-radius: 0px 0px 0px 29px;
}

.collections_accessories-bottom {
    width: 100%;
    height: 30%;
    background-color: rgba(96, 183, 199, 0.24);;
}

// .collections_accessories-btn {
//     width: 273px;
//     height: 51px;
//     background: #60B7C7;
//     border-radius: 4px;
//     border: hidden;
//     color: white;
//     font-family: 'GothamMedium';
//     font-size: 18px;
// }

.collections_accessories-btn {
    position: absolute;
    left: 5%;
    bottom: 10%;
    width: 273px;
    height: 51px;
    background: #96AC8F;
    border-radius: 4px;
    border: hidden;
    color: white;
    font-family: 'GothamMedium';
    font-size: 18px;
}

.collections_promo-img {
    width: 800px;
    object-fit: cover;
}
