.bar-title-source {
    background: #f2f2f2;
}

.select-source-container {
    border: 1px solid;
    border-color: #f2f2f2;
}

.close-view {
    opacity: .4;
    width: 50px;
    height: 63px;
}

.close-cover {
    opacity: 1;
}

.title-full-view {
    margin-left: -15px;
}

.btn-header {
    width: 95px;
}

.vertical-spine-text-change {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    // right: -3px;2
    font-size: 14px;
    position: absolute;
    top: 120px;
    right: -30px;
    width: 195px;
    margin-right: -81px;
    z-index: 2;
}

.shadow-page-right {
    -webkit-box-shadow: 11px 0px 11px 6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 11px 0px 11px 6px rgba(0, 0, 0, 0.75);
    box-shadow: 11px 0px 11px 6px rgba(0, 0, 0, 0.75);
}

.bg-spine {
    background: white;
    z-index: 1;
}

.book {
    margin: 0 auto;
}

.change-cover-container {
    border: solid 1px;
    border-color: rgba(0, 0, 0, 0.1);
}


///////////////////////////

.changecover-selectsource_main {
    width: 100%;
    height: 100%;
}

.changecover-selectsource_header {
    height: 8vh;
    background-color: rgba(0,0,0,0.05);
}

.changecover-selectsource_close {
    width: 8vh;
    height: 8vh;
    background-color: #51b7f3;
}

.changecover-selectsource_header-right {
    width: 100%;
}

.source-buttons_main {
    height: 220px;
}


.source-buttons_top {
    height: 25%;
    background-color: #51b7f3;
}

.source-buttons_bottom {
    height: 75%;
}

.changecover-selectsource_content {
    width: 100%;
}

.changecover-selectsource_back-container {
    height: 6vh; 
    width: 90%;
}

.changecover-selectsource_photos {
    width: 90%;
}

.selectquotes-item_img {
    height: 85%;
    width: 100%;
    object-fit: cover;
}

.selectquotes-item_cat-name {
    height: 15%;
    width: 100%;
    background-color: white;
}

.selectquotes-item_cathegory {
    height: 95%;
    width: 90%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.selectquotes-item_cathegory:hover {
    height: 95%;
    width: 90%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}












.changecover-selectsource_inside {
    // width: 50%;
    // height: 85%;
    // min-width: 700px;
    // min-height: 500px;
    width: 800px;
    height: 700px;
}

.changecover-selectsource_inside-top {
    width: 100%;
    height: 7%;
}

.changecover-selectsource_inside-bottom {
    width: 100%;
    height: 93%;
    // background-color: white;
}

.changecover-selectsource_cover {
    background: url('../../images/newDesignImages/album-cover-img.jpg') no-repeat center;
    background-size: contain;
    width: 800px;
    height: 60%;
}

.changecover-selectsource_edit-options {
    width: 800px;
    height: 40%;
}

.changecover-selectsource_edit-container {
    background-color: white;
    height: 40%;
    width: 800px;
    border: #00baf1 4px solid;
    border-radius: 20px;
}

.changecover-selectsource_edit-photo {
    position: relative;
    top: -145px;
    right: -345px;
    width: 50px;
    height: 50px;
    background-color: #00baf1;
    border: white 4px solid;
    border-radius: 10px;
}
