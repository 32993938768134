.ordersummary_container {
    width: 100%;
}

.ordersummary_cell {
    height: 50px;
}

.summary_buy-btn {
    width: 95%;
    height: 60px;
    border: hidden;
    border-radius: 5px;
    background-color: #51B7F3;
}

.order-success_oxxo-instructions {
    border-top: #CCCCCC solid 1px;
}