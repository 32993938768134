
.border-pixypesos {
  border-width: 3px;
  border-style: dashed;
  border-color: gray;
  -moz-border-radius: 20px;
  -webkit-border-radius:20px;
}

.bg-code {
  background: rgba(129, 110, 216, 0.2);
}

.border-pink {
  border: 3px solid #816ed8;
}

.center-img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.cover-image {
  width: 100%;
  height: auto;
}