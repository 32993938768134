@font-face {
    font-family: 'Didot';
    font-style: bold;
    font-weight: 400;
    src: url(./fonts/Didot-Bold.woff), url(./fonts/Didot-HTF-B06-Bold.ttf);
}

@font-face {
    font-family: 'AvantGarde';
    font-style: normal;
    font-weight: normal;
    src: url(./fonts/ITC-Avant-Garde-Gothic-LT-Medium.ttf);
}

@font-face {
    font-family: 'LeahGaviota';
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/LeahGaviotaBold.ttf);
}

.add-title-input {
    height: 5vh;
    width: 600px;
    margin-bottom: 5px;
    margin-top: 5px;
    border: rgba(0,0,0,0.4) solid 1px;
    border-radius: 10px;
    font-size: 18px;
}

.add-title-input input {
    width: 90%;
    border: none;
}

.edit-options {
    background-color: rgba(0,0,0,0.1);
    width: 40%;
    height: 40px;
}

.color-circle-black {
    height: 30px;
    width: 30px;
    background-color: rgb(0,0,0);
    border-radius: 20px;
}

.color-circle-white {
    height: 30px;
    width: 30px;
    border: solid 2px;
    border-color: rgba(0,0,0,0.3);
    border-radius: 20px;
}

.color-circle-gray {
    height: 30px;
    width: 30px;
    background-color: rgb(136, 136, 136);
    border-radius: 20px;
}

.position-icon {
    height: 35px;
    width: 35px;
    border: solid 1px;
    border-color: rgba(0,0,0,0.2);
    border-radius: 2px;
}

///////////////////

.add-title_container {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    padding: 5% 0;
}



.add-title_inner-container {
    // width: 60%;
    // min-width: 800px;
    width: 800px;
    overflow-y: scroll;
}

.add-title_top-container {
    height: 40px;
}

.add-title_inner-inside {
    height: auto;
    width: 100%;
    background-color: white;
    border: #00baf1 solid 4px;
    border-radius: 10px;
}

.add-title_customize {
    width: 600px;
}

.add-title_fonts {
    height: 30px;
    width: 200px;
    border: rgba(0,0,0,0.6) 1.7px solid;
    border-radius: 40px;
}

.add-title_fonts-dropdown {
    display: none;
    position: absolute;
    background-color: white;
    padding: 2px 10px;
    width: 200px;
}

.add-title_save-btn {
    border: hidden;
    height: 40px;
    background-color: #00baf1;
}

.add-title_save-btn:hover {
    border: hidden;
    height: 40px;
    background-color: #2594cc;
}